import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { baseFormsRoutes } from './baseFormsRoutes';
import { withPrivateRoute } from '../withPrivateRoute';

const { PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const consultRoutes = {
  name: 'Consults',
  path: '/consults',
  type: 'layout',
  ...withPrivateRoute(undefined, {
    userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
  }),
  children: [
    {
      name: 'Consults',
      index: true,
      path: '',
      type: 'item',
      ...withPrivateRoute(
        Loadable(React.lazy(() => import('../../../components/dashboard/consults/consults-container'))),
        {
          userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
        },
      ),
    },
    ...baseFormsRoutes,
    {
      name: 'Checkup Form',
      path: 'checkup-form',
      type: 'item',
      element: Loadable(React.lazy(() => import('../../../components/form/other-forms/checkup-form'))),
    },
  ],
};
