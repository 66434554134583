/**
 * FIXME: This is mostly copied from the old useAccountStatus hook.
 * We should refactor this to be more maintainable and easier to understand.
 */

import { useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../firebase-config';
import { updateEmailInDB } from '../utils/authHelpers';

// FUTURE: Migrate our db away from using these 3 fields, move to a single field for
// userType/accountType/userRole and not different values and status e.g "PENDING", "APPROVED", "SUSPENDED"
const USER_ACCOUNTS_NEEDING_APPROVAL = ['isSupplier', 'isDoctor', 'isPharmacy'];
/**
 * Checks if the user's account is currently pending approval.
 * This data should come from the 'patients' collection.
 * Only specific users can be in a pending approval state like Doctors, Pharamacists, or Suppliers
 * TODO: QUESTION: Currently unsure why we're checking for offline??? Someone please explain/update this comment.
 * FUTURE: FIX-ME: type "offline" should be moved to constants once it's clear what it's purpose is.
 * @param {Object} params - The parameters object.
 * @param {Object} params.userData - The user data in firestore to check.
 * @param {string[]} [params.fieldNamesToCheck] - List of the fieldNames to check for user account roles that require approval.
 * @returns {boolean} True if the user account is pending approval, false otherwise.
 */
export const isAccountPendingApproval = ({ userData = {}, fieldNamesToCheck = USER_ACCOUNTS_NEEDING_APPROVAL }) =>
  fieldNamesToCheck.some((role) => !!userData?.[role]) && userData?.type === 'offline';

/**
 * @param {Object} props
 * @param {import('firebase/auth').User | null} props.user
 * @param {string} props.userType
 * @param {boolean} props.isAuthLoading
 * @param {boolean} props.isAccountPending
 * @param {React.Dispatch<React.SetStateAction<{isEmailVerified: boolean, oldEmail: string, newEmail: string}>>} props.setEmailVerificationDetails
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsAccountPending
 */
export const useAuthAccountStatus = ({
  user,
  userType,
  isAuthLoading,
  isAccountPending,
  setEmailVerificationDetails,
  setIsAccountPending,
}) => {
  // TODO: FIXME: There are bugs here, look at the effects dependencies, this needs to be cleaned up
  useEffect(() => {
    // FIXME: This is hacky, clean this up to be more maintainable. Maybe start from scratch.
    const fetchAndEvaluateUserStatus = async () => {
      if (!user) {
        return;
      }
      try {
        const userDocRef = doc(db, 'patients', user?.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();

        if (!userData) {
          return;
        }

        // This is the solution for unauthenticated email recovery and unauthenticated email change since they can't update the userDoc email when they are not logged in
        // NOTE: this will also sync the auth user email with the firestore user patient email
        if (user?.email !== userData.email) {
          const result = await updateEmailInDB({
            existingEmail: userData.email,
            newEmail: user?.email,
            operation: 'CHANGE_EMAIL',
            user,
            userType,
          });

          if (result) {
            setEmailVerificationDetails((previousState) => ({
              ...previousState,
              isEmailVerified: true,
            }));
          }

          return;
        }

        setIsAccountPending(isAccountPendingApproval({ userData }));
      } catch (error) {
        sentryCaptureException(error, {
          extra: { isAuthLoading, isAccountPending, user, userType },
        });
      }
    };

    fetchAndEvaluateUserStatus();
  }, [user, userType, isAccountPending, isAuthLoading, setEmailVerificationDetails, setIsAccountPending]);
};
