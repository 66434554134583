import React from 'react';

import { useAuth } from '../../hooks/useAuth';
import { USER_TYPES } from '../../utils/constants';
import { Banner } from './Banner';

export const CdaClinicsJoinsCandorBanner = () => {
  const { userType } = useAuth();

  if (userType !== USER_TYPES.PATIENT) {
    return null;
  }

  return (
    <Banner
      title="CDA Clinics is now part of Candor Medical"
      description="We're excited to welcome CDA Clinics patients to the Candor Medical family. As we integrate and train new team members, there may be a short delay in initial assessments. Thank you for your continued patience as we grow our team to continue delivering the quality care you expect from us at Candor."
      colour="info"
    />
  );
};
