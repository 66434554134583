/**
 * @typedef {|
 * "auth/user-disabled"
 * | "auth/multi-factor-auth-required"
 * | "auth/invalid-email"
 * | "auth/user-not-found"
 * | "auth/wrong-password"
 * | "auth/invalid-credential"
 * | "auth/invalid-verification-code"
 * | "auth/argument-error"
 * | "auth/too-many-requests"
 * | "auth/weak-password"
 * | "auth/email-already-in-use"
 * | "auth/invalid-verification-id"
 * | "auth/requires-recent-login"
 * | "auth/too-many-enrollment-attempts"
 * } MFAErrorCode
 */

/**
 * @typedef {|
 * "USER_DISABLED"
 * | "MULTI_FACTOR_AUTH_REQUIRED"
 * | "INVALID_EMAIL"
 * | "USER_NOT_FOUND"
 * | "WRONG_PASSWORD"
 * | "INVALID_CREDENTIAL"
 * | "INVALID_VERIFICATION_CODE"
 * | "ARGUMENT_ERROR"
 * | "TOO_MANY_REQUESTS"
 * | "WEAK_PASSWORD"
 * | "EMAIL_ALREADY_IN_USE"
 * | "INVALID_VERIFICATION_ID"
 * | "REQUIRES_RECENT_LOGIN"
 * | "TOO_MANY_ENROLLMENT_ATTEMPTS"
 * } MFAErrorCodeKey
 */

/**
 * @satisfies {Record<MFAErrorCodeKey, MFAErrorCode>}
 */
export const MFA_ERROR_CODES = {
  USER_DISABLED: 'auth/user-disabled',
  MULTI_FACTOR_AUTH_REQUIRED: 'auth/multi-factor-auth-required',
  INVALID_EMAIL: 'auth/invalid-email',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  INVALID_CREDENTIAL: 'auth/invalid-credential',
  INVALID_VERIFICATION_CODE: 'auth/invalid-verification-code',
  ARGUMENT_ERROR: 'auth/argument-error',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  WEAK_PASSWORD: 'auth/weak-password',
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  INVALID_VERIFICATION_ID: 'auth/invalid-verification-id',
  REQUIRES_RECENT_LOGIN: 'auth/requires-recent-login',
  TOO_MANY_ENROLLMENT_ATTEMPTS: 'auth/too-many-enrollment-attempts',
};
