import React from 'react';
import { Typography } from '@mui/material';

/**
 * @param {import('@mui/material').TypographyProps} props
 */
export const Title = ({ children, ...props }) => (
  <Typography variant="h5" align="center" {...props}>
    {children}
  </Typography>
);
