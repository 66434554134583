import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Stack, useTheme } from '@mui/material';

import { useAuth } from '../../../../hooks/useAuth';
import { AuthenticationWrapper, classes } from '../../AuthenticationWrapper';
import { NUMBER_OF_OTP_INPUTS, OTPInput, parseOTPInputValue } from '../../components/OTPInput';
import { SetupPage } from '../SetupPage';

const COOLDOWN_MS = 35000; // 35 seconds
const ONE_SECOND_MS = 1000;

/**
 * @param {Object} props
 * @param {number} props.lastSent - The timestamp of the last sent verification code
 * @param {() => Promise<void>} props.handleSendVerificationCode - The function to handle sending the verification code
 */
const ResendCodeButton = ({ lastSent, handleSendVerificationCode }) => {
  const [remainingCooldown, setRemainingCooldown] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingCooldown(Math.floor((COOLDOWN_MS - (Date.now() - lastSent)) / ONE_SECOND_MS));
    }, ONE_SECOND_MS);

    return () => clearInterval(interval);
  }, [lastSent]);

  return (
    <Button variant="text" onClick={handleSendVerificationCode} disabled={remainingCooldown > 0}>
      {remainingCooldown > 0 ? `Resend code in ${remainingCooldown} seconds` : 'Resend verification code'}
    </Button>
  );
};

export const VerifyCodeSection = ({ onSubmit, lastSent, handleSendVerificationCode }) => {
  const theme = useTheme();
  const { user } = useAuth();

  /**
   * @type {import('react-hook-form').UseFormReturn<SMSFormType>}
   */
  const form = useFormContext();

  const {
    formState: { isSubmitting },
  } = form;

  const code = form.watch('verificationCode');

  if (!user) {
    return null;
  }

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      <OTPInput onLastInput={form.handleSubmit(onSubmit)} />
      <Typography variant="caption" color="text.secondary" align="center">
        Enter the {NUMBER_OF_OTP_INPUTS}-digit code sent to your phone
      </Typography>

      {form.formState.errors.verificationCode && (
        <Typography color="error" variant="caption">
          {form.formState.errors.verificationCode.message}
        </Typography>
      )}

      {form.formState.errors.root?.message && (
        <Typography color="error" variant="caption">
          {form.formState.errors.root.message}
        </Typography>
      )}

      <ResendCodeButton
        lastSent={lastSent}
        handleSendVerificationCode={form.handleSubmit(handleSendVerificationCode)}
      />

      <AuthenticationWrapper.Actions style={{ flexDirection: 'row', gap: theme.spacing(2) }}>
        <SetupPage.CancelButton disabled={isSubmitting} user={user} />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          loading={isSubmitting}
          loadingPosition="end"
          disabled={parseOTPInputValue(code).length !== NUMBER_OF_OTP_INPUTS}
        >
          {isSubmitting ? 'Setting up' : 'Verify and Enable'}
        </Button>
      </AuthenticationWrapper.Actions>
    </Stack>
  );
};
