import React from 'react';

import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { withPrivateRoute } from '../withPrivateRoute';

const PurchaseHistory = React.lazy(() => import('../../../components/purchase-history/purchase-history-container'));

const { PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const purchaseHistoryRoutes = {
  name: 'Purchase History',
  path: 'purchase-history',
  type: 'item',
  ...withPrivateRoute(<PurchaseHistory />, {
    userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
  }),
};
