import { captureException as sentryCaptureException } from '@sentry/react';

import { MFA_ERROR_CODES } from '../constants';
/**
 * Handles Firebase-specific errors during MFA verification
 * @param {Object} params
 * @param {import('react-hook-form').UseFormSetError<{ verificationCode: { value: string; }[] }>} params.setError
 * @param {import('@firebase/app').FirebaseError} params.error
 * @param {() => void} [params.handleRequiresRecentLogin]
 */
export const handleFirebaseError = ({ setError, error, handleRequiresRecentLogin }) => {
  if (error.code === MFA_ERROR_CODES.REQUIRES_RECENT_LOGIN) {
    handleRequiresRecentLogin?.();

    return;
  }

  if (error.code === MFA_ERROR_CODES.INVALID_VERIFICATION_CODE) {
    setError('verificationCode', {
      message: 'Invalid verification code. Please try again.',
    });

    return;
  }

  if (error.code === MFA_ERROR_CODES.INVALID_VERIFICATION_ID) {
    setError('verificationCode', {
      message: 'Invalid verification ID. Please try again.',
    });

    return;
  }

  if (error.code === MFA_ERROR_CODES.TOO_MANY_ENROLLMENT_ATTEMPTS) {
    setError('root', {
      message: 'You have reached the maximum number of attempts. Please try again later.',
    });
  }

  const sentryIssueId = sentryCaptureException(error, {
    level: 'info',
    extra: { issueIn: 'handleFirebaseError' },
  });

  setError('root', {
    type: 'sentry',
    message: `An unexpected error occurred. Please try again. Id: ${sentryIssueId}`,
  });
};
