import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { NUMBER_OF_OTP_INPUTS, OTPInput } from '../OTPInput';

/**
 * @typedef {Object} TOTPFactorProps
 * @property {() => void} onSubmit
 */

/**
 * TOTP Factor component for handling authenticator app verification
 * @param {TOTPFactorProps} props
 */
export const TOTPFactor = ({ onSubmit }) => {
  const {
    formState: { errors },
  } = useFormContext();

  /** @type {import('react-hook-form').UseFormReturn<{ verificationCode: { value: string; }[] }>} */

  return (
    <Stack spacing={1} alignItems="center">
      <OTPInput onLastInput={onSubmit} />
      <FormHelperText sx={{ textAlign: 'center' }}>
        Enter the {NUMBER_OF_OTP_INPUTS}-digit code from your authenticator app
      </FormHelperText>
      {errors.verificationCode?.message && (
        <Typography color="error" variant="caption">
          {errors.verificationCode.message.toString()}
        </Typography>
      )}
      {errors.root?.message && (
        <Typography color="error" variant="caption">
          {errors.root.message}
        </Typography>
      )}
    </Stack>
  );
};
