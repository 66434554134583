import { FirebaseError } from 'firebase/app';
import { useFormContext } from 'react-hook-form';
import { Typography, Stack, Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useEffect, useRef, useState } from 'react';
import { PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { captureException as sentryCaptureException } from '@sentry/react';

import { auth } from '../../../../firebase-config';
import { handleFirebaseError } from '../../utils';
import { NUMBER_OF_OTP_INPUTS, OTPInput } from '../OTPInput';

const COOLDOWN_MS = 35000;
const ONE_SECOND_MS = 1000;

/**
 * @typedef {Object} SMSFactorProps
 * @property {import('@firebase/auth').MultiFactorInfo} factor
 * @property {import('@firebase/auth').MultiFactorSession} session
 * @property {() => void} onSubmit
 */

/**
 * @param {Object} props
 * @param {import('@firebase/auth').MultiFactorInfo} props.factor
 * @param {import('@firebase/auth').MultiFactorSession} props.session
 */
const ActionButton = ({ factor, session }) => {
  const [lastSent, setLastSent] = useState(0);
  const [remainingCooldown, setRemainingCooldown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaVerifierRef = useRef(/** @type {RecaptchaVerifier | null} */ (null));

  const {
    setError,
    setValue,
    /** @type {import('react-hook-form').UseFormReturn<{ verificationCode: { value: string; }[] }>} */
  } = useFormContext();

  useEffect(
    () => () => {
      if (recaptchaVerifierRef.current !== null) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }
    },
    [factor],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingCooldown(Math.floor((COOLDOWN_MS - (Date.now() - lastSent)) / ONE_SECOND_MS));
    }, ONE_SECOND_MS);
    return () => clearInterval(interval);
  }, [lastSent]);

  const sendSMSCode = async () => {
    try {
      setIsLoading(true);

      if (recaptchaVerifierRef.current === null) {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
        });
        await recaptchaVerifierRef.current.verify();
      }

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          session,
          multiFactorUid: factor.uid,
        },
        recaptchaVerifierRef.current,
      );

      setValue('verificationId', newVerificationId);
      setLastSent(Date.now());
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleFirebaseError({ error, setError });

        return;
      }

      const sentryIssueId = sentryCaptureException(error, {
        level: 'error',
        extra: { issueIn: 'SMSFactor:sendSMSCode' },
      });

      setError('root', {
        type: 'sentry',
        message: `Failed to send verification code. Please try again. Id: ${sentryIssueId}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (lastSent === 0) {
    return (
      <Button variant="text" onClick={sendSMSCode} disabled={remainingCooldown > 0} loading={isLoading}>
        Send verification code
      </Button>
    );
  }

  return (
    <Button variant="text" onClick={sendSMSCode} disabled={remainingCooldown > 0} loading={isLoading}>
      {remainingCooldown > 0 ? `Resend code in ${remainingCooldown} seconds` : 'Resend verification code'}
    </Button>
  );
};

/**
 * SMS Factor component for handling phone verification
 * @param {SMSFactorProps} props
 */
export const SMSFactor = ({ onSubmit, factor, session }) => {
  const {
    formState: { errors },
    /** @type {import('react-hook-form').UseFormReturn<{ verificationCode: { value: string; }[] }>} */
  } = useFormContext();

  return (
    <Stack spacing={1} alignItems="center">
      <div id="recaptcha-container" />

      <OTPInput onLastInput={onSubmit} />

      <FormHelperText sx={{ textAlign: 'center' }}>
        Enter the {NUMBER_OF_OTP_INPUTS}-digit code sent to your phone
      </FormHelperText>

      {errors.verificationCode?.message && (
        <Typography color="error" variant="caption">
          {errors.verificationCode.message.toString()}
        </Typography>
      )}

      {errors.root?.message && (
        <Typography color="error" variant="caption">
          {errors.root.message}
        </Typography>
      )}

      <ActionButton factor={factor} session={session} />
    </Stack>
  );
};
