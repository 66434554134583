import { useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { captureException } from '@sentry/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router';
import React, { useCallback, useState, useRef } from 'react';
import { Typography, Button, Stack, TextField, useTheme } from '@mui/material';
import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, multiFactor } from 'firebase/auth';

import { auth } from '../../../../firebase-config';
import { useAuth } from '../../../../hooks/useAuth';
import { AuthenticationModal } from '../../components';
import { AuthenticationWrapper, classes } from '../../AuthenticationWrapper';
import { useAuthenticationPageContext } from '../../useAuthenticationPageContext';
import { DEFAULT_OTP_INPUT_FIELD_VALUE, parseOTPInputValue } from '../../components/OTPInput';
import { SetupPage } from '../SetupPage';
import { addSuccessful2FAToActivityLog } from '../addSuccessful2FAToActivityLog';
import { smsSchema } from './smsSchema';
import { VerifyCodeSection } from './VerifyCodeSection';

/**
 * Handles Firebase-specific errors during SMS setup
 * @param {Object} params
 * @param {() => void} params.handleRequiresRecentLogin - Handles the requires recent login error
 * @param {import('react-hook-form').UseFormSetError<SMSFormType>} params.setError
 * @param {FirebaseError} params.error - Firebase error object
 */
const handleFirebaseError = ({ handleRequiresRecentLogin, setError, error }) => {
  if (error.code === 'auth/invalid-verification-code') {
    setError('verificationCode', {
      message: 'Invalid verification code. Please try again.',
    });

    return;
  }

  if (error.code === 'auth/invalid-phone-number') {
    setError('phoneNumber', {
      message: 'Invalid phone number. Please enter a valid phone number with country code.',
    });

    return;
  }

  if (error.code === 'auth/requires-recent-login') {
    handleRequiresRecentLogin();

    return;
  }

  setError('root', {
    message: 'An unexpected error occurred. Please try again.',
  });
};

export const SMSSetupPage = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { snackbar } = useAuthenticationPageContext();
  const recaptchaVerifierRef = useRef(/** @type {RecaptchaVerifier | null} */ (null));

  // State to track the current step
  const [lastSent, setLastSent] = useState(0);
  const [verificationId, setVerificationId] = useState('');
  const [requiresReauthentication, setRequiresReauthentication] = useState(false);

  /**
   * @type {import('react-hook-form').UseFormReturn<SMSFormType>}
   */
  const form = useForm({
    resolver: zodResolver(smsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      phoneNumber: searchParams.get('phoneNumber') || '',
      verificationCode: DEFAULT_OTP_INPUT_FIELD_VALUE,
    },
  });

  const {
    setError,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const handleRequiresRecentLogin = useCallback(() => {
    setRequiresReauthentication(true);
  }, []);

  /**
   * Handles the sending of the verification code
   * @type {(data: SMSFormType) => Promise<void>}
   */
  const handleSendVerificationCode = useCallback(
    async (data) => {
      if (!user) {
        return;
      }

      try {
        const multiFactorUser = multiFactor(user);
        const session = await multiFactorUser.getSession();

        if (recaptchaVerifierRef.current === null) {
          recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
          });

          await recaptchaVerifierRef.current.verify();
        }

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          {
            phoneNumber: data.phoneNumber,
            session,
          },
          recaptchaVerifierRef.current,
        );

        setVerificationId(newVerificationId);
        setLastSent(Date.now());
        snackbar('Verification code sent successfully');
      } catch (error) {
        if (error instanceof FirebaseError) {
          handleFirebaseError({
            error,
            setError,
            handleRequiresRecentLogin,
          });

          return;
        }

        const sentryIssueId = captureException(error, {
          level: 'error',
          extra: {
            phoneNumber: data.phoneNumber,
          },
        });

        setError('root', {
          type: 'sentry',
          message: `Failed to send verification code. Please try again. Id: ${sentryIssueId}`,
        });
      }
    },
    [user, snackbar, setError, handleRequiresRecentLogin],
  );

  /**
   * Handles the verification of the SMS code
   * @type {(data: SMSFormType) => Promise<void>}
   */
  const handleVerifyCode = useCallback(
    async (data) => {
      if (!verificationId || !user || !data.verificationCode) {
        return;
      }

      try {
        const parsedVerificationCode = parseOTPInputValue(data.verificationCode);
        const credential = PhoneAuthProvider.credential(verificationId, parsedVerificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

        const { phoneNumber } = getValues();

        await multiFactor(user).enroll(multiFactorAssertion, phoneNumber);

        await addSuccessful2FAToActivityLog({ userId: user.uid, factorId: 'phone' });

        const urlSearchParams = new URLSearchParams(window.location.search);
        const redirect = urlSearchParams.get('redirect');

        if (redirect) {
          urlSearchParams.delete('redirect');
          navigate(`${redirect}?${urlSearchParams.toString()}`);
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
        if (error instanceof FirebaseError) {
          handleFirebaseError({
            error,
            setError,
            handleRequiresRecentLogin,
          });

          return;
        }

        const sentryIssueId = captureException(error, {
          level: 'error',
          extra: {
            phoneNumber: data.phoneNumber,
            verificationCode: data.verificationCode,
          },
        });

        setError('root', {
          type: 'sentry',
          message: `Invalid verification code. Please try again. Id: ${sentryIssueId}`,
        });
      }
    },
    [user, verificationId, setError, getValues, handleRequiresRecentLogin],
  );

  /**
   * Handles the submission of the form
   * @type {(data: SMSFormType) => Promise<void>}
   */
  const onSubmit = useCallback(
    async (data) => {
      if (!verificationId) {
        await handleSendVerificationCode(data);

        return;
      }

      await handleVerifyCode(data);
    },
    [verificationId, handleSendVerificationCode, handleVerifyCode],
  );

  const handleReauthenticationSuccess = useCallback(async () => {
    await auth.currentUser?.reload();
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  if (!user) {
    return null;
  }

  return (
    <SetupPage
      form={form}
      method="phone"
      title="Set Up SMS Authentication"
      subtitle="Enhance your account security by setting up two-factor authentication using SMS"
      onSubmit={onSubmit}
      containerProps={{
        style: {
          maxWidth: '400px',
        },
      }}
    >
      <div id="recaptcha-container" />
      <Stack spacing={3} alignItems="center" width="100%">
        <Stack spacing={2} width="100%" alignItems="center">
          <TextField
            fullWidth
            disabled={!!verificationId}
            label="Phone Number"
            autoComplete="tel"
            placeholder="+1234567890"
            {...form.register('phoneNumber')}
            error={!!form.formState.errors.phoneNumber}
            helperText={form.formState.errors.phoneNumber?.message}
          />

          {!verificationId && (
            <AuthenticationWrapper.Actions style={{ flexDirection: 'row', gap: theme.spacing(2) }}>
              <SetupPage.CancelButton disabled={isSubmitting} user={user} />

              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
                loading={isSubmitting}
                loadingPosition="end"
                fullWidth
              >
                Send Verification Code
              </Button>
            </AuthenticationWrapper.Actions>
          )}
        </Stack>

        {!!verificationId && (
          <VerifyCodeSection
            onSubmit={handleVerifyCode}
            lastSent={lastSent}
            handleSendVerificationCode={handleSendVerificationCode}
          />
        )}

        {form.formState.errors.root?.message && (
          <Typography color="error" variant="caption" textAlign="center">
            {form.formState.errors.root.message}
          </Typography>
        )}
      </Stack>
      <AuthenticationModal
        nonDismissable
        modalOpen={requiresReauthentication}
        setModalOpen={setRequiresReauthentication}
        mode="reauthenticate"
        onSuccess={handleReauthenticationSuccess}
      />
    </SetupPage>
  );
};
