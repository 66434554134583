import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { useAuth } from '../hooks/useAuth';

/**
 * @param {Object} params
 * @param {ExtendedRouteObject['userCanAccess']} params.userCanAccess
 * @param {React.ReactNode} params.children
 */
export const PrivateRoute = ({ userCanAccess, children }) => {
  const { userType } = useAuth();

  if (!userCanAccess?.({ userType })) {
    return <Navigate to="/" />;
  }

  return children ?? <Outlet />;
};
