const PREFIX = 'AuthenticationPage';

export const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  form: `${PREFIX}-form`,
  link: `${PREFIX}-link`,
  gridContainer: `${PREFIX}-gridContainer`,
  loginButton: `${PREFIX}-loginButton`,
  cancelButton: `${PREFIX}-cancelButton`,
  actions: `${PREFIX}-actions`,
  container: `${PREFIX}-container`,
  pageContainer: `${PREFIX}-pageContainer`,
  header: `${PREFIX}-header`,
};
