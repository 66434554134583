import { useEffect } from 'react';

import { isInIFrame } from '../utils';

const onResize = () => {
  if (!isInIFrame()) {
    return;
  }

  window.parent.postMessage(
    {
      type: 'resize',
      height: document.body.scrollHeight,
    },
    window.location.origin,
  );
};

/**
 * This is a hook that is used to resize the iframe when the content inside it changes.
 * It is used to ensure that the iframe is the same height as the content inside it.
 * This is necessary because the iframe is a fixed height and the content inside it can change.
 */
export const useIFrameResizeObserver = () => {
  useEffect(() => {
    if (!isInIFrame()) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
};
