import React from 'react';

import { Loadable } from '../../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../../utils/constants';
import { withPrivateRoute } from '../../withPrivateRoute';

const PharmacyOrderDetailsPage = React.lazy(() =>
  import('../../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyOrderDetailsPage,
  })),
);

const PharmacyDetailsPage = React.lazy(() =>
  import('../../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyDetailsPage,
  })),
);

const PharmacyOrdersPage = React.lazy(() =>
  import('../../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyOrdersPage,
  })),
);

const PharmaciesPage = React.lazy(() =>
  import('../../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmaciesPage,
  })),
);

const PharmacyDetailsLayout = React.lazy(() =>
  import('../../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyDetailsLayout,
  })),
);

const { PHARMACIST, PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const pharmaciesRoutes = {
  name: 'Pharmacies',
  path: 'pharmacies',
  type: 'parent',
  ...withPrivateRoute(undefined, {
    userCanAccess: ({ userType }) => !allowedUserType([PHARMACIST], userType),
  }),
  children: [
    {
      name: 'Pharmacies',
      index: true,
      path: '',
      type: 'item',
      ...withPrivateRoute(Loadable(PharmaciesPage), {
        userCanAccess: ({ userType }) => !allowedUserType([PHARMACIST], userType),
      }),
    },
    {
      name: 'Pharmacy Details',
      path: ':pharmacyId',
      type: 'item',
      ...withPrivateRoute(Loadable(PharmacyDetailsLayout), {
        userCanAccess: ({ userType }) => !allowedUserType([PATIENT], userType),
      }),

      children: [
        {
          name: 'Pharmacy Details',
          index: true,
          path: '',
          type: 'item',
          element: <PharmacyDetailsPage />,
        },
        {
          name: 'Pharmacy Stock Levels',
          path: 'stock-levels',
          type: 'item',
          element: Loadable(
            React.lazy(() => import('../../../../features/pharmacyDirectory/components/PharmacyStockPreview')),
          ),
        },
        {
          name: 'Pharmacy Orders',
          path: 'orders',
          type: 'parent',
          children: [
            {
              name: 'Pharmacy Orders',
              index: true,
              path: '',
              type: 'item',
              element: <PharmacyOrdersPage />,
            },
            {
              name: 'Pharmacy Order Details',
              path: ':orderId',
              type: 'item',
              element: <PharmacyOrderDetailsPage />,
            },
          ],
        },
      ],
    },
  ],
};
