import React from 'react';

import { PrivateRoute } from '../PrivateRoute';

/**
 * @param {React.ReactNode | undefined} element
 * @param {Object} params
 * @param {ExtendedRouteObject['userCanAccess']} params.userCanAccess
 * @returns {{element: ExtendedRouteObject['element'], userCanAccess: ExtendedRouteObject['userCanAccess']}}
 */
export const withPrivateRoute = (element, { userCanAccess }) => ({
  element: <PrivateRoute userCanAccess={userCanAccess}>{element}</PrivateRoute>,
  userCanAccess,
});
