import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { multiFactor } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { captureException } from '@sentry/react';

import { useAuth } from '../../../../hooks/useAuth';
import { MFA_ERROR_CODES } from '../../constants';
import { getNextMFAFactorIdNeedingSetup } from '../../utils';
import { AuthenticationWrapper, classes } from '../../AuthenticationWrapper';
import { CancelButton } from './CancelButton';
import { useMFASetupPageContext } from './useMFASetupPageContext';
import { MFASetupPageContextProvider } from './MFASetupPageContextProvider';

/**
 * @type {FactorId[]}
 */
const FACTOR_IDS_TO_UNENROLL = ['phone'];

/**
 * Handles unenrollment of deprecated 2FA factors when a new MFA method is required
 * @param {Object} params
 * @param {import('../../../../utils/constants').UserType | ""} params.userType
 * @param {import('firebase/auth').MultiFactorUser} params.multiFactorUser
 * @param {() => void} params.reauthenticate
 * @param {() => Promise<void>} params.handleLogout
 */
const handleDeprecated2FAUnenrollment = async ({ userType, multiFactorUser, reauthenticate, handleLogout }) => {
  const { enrolledFactors } = multiFactorUser;

  const nextMFAFactorIdNeedingSetup = getNextMFAFactorIdNeedingSetup({ userType, multiFactorUser });

  // If the user doesn't need to setup MFA, we can skip the unenrollment
  if (!nextMFAFactorIdNeedingSetup) {
    return;
  }

  // Find and unenroll in the deprecated factors
  const deprecatedFactors = enrolledFactors.filter((factor) => FACTOR_IDS_TO_UNENROLL.includes(factor.factorId));

  await Promise.all(
    deprecatedFactors.map(async (factor) => {
      try {
        await multiFactorUser.unenroll(factor);
      } catch (error) {
        if (error instanceof FirebaseError && error.code === MFA_ERROR_CODES.REQUIRES_RECENT_LOGIN) {
          reauthenticate();

          return;
        }

        // Silent error for the user
        captureException(error, { extra: { enrolledFactors, multiFactorUser } });

        await handleLogout();
      }
    }),
  );
};

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @typedef {Object} SetupPagePropsBase
 * @property {FactorId} method
 * @property {string} title
 * @property {string} subtitle
 * @property {React.ComponentProps<typeof AuthenticationWrapper.Container>} [containerProps]
 * @property {import('react').ReactNode} children
 */

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @typedef {SetupPagePropsBase<TFormValues> & ({
 *  form: import('react-hook-form').UseFormReturn<TFormValues>,
 *  onSubmit: (data: TFormValues) => Promise<void>
 * } | {
 *  form?: never,
 *  onSubmit: () => Promise<void>
 * })} SetupPageProps
 */

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @typedef {Omit<SetupPagePropsBase<TFormValues>, 'method'> & ({
 *  form: import('react-hook-form').UseFormReturn<TFormValues>,
 *  onSubmit: (data: TFormValues) => Promise<void>
 * } | {
 *  form?: never,
 *  onSubmit: () => Promise<void>
 * })} SetupPagePropsWithoutFactorId
 */

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @param {SetupPagePropsWithoutFactorId<TFormValues>} props
 */
const SetupPageContent = ({ title, subtitle, children, containerProps, ...props }) => {
  const { user, userType } = useAuth();
  const { handleLogout, reauthenticate } = useMFASetupPageContext();

  /**
   * @type {import('react').RefCallback<HTMLDivElement>}
   */
  const callbackRef = useCallback(
    (node) => {
      if (!node || !user) {
        return;
      }

      handleDeprecated2FAUnenrollment({
        userType,
        handleLogout,
        reauthenticate,
        multiFactorUser: multiFactor(user),
      });
    },
    [user, userType, handleLogout, reauthenticate],
  );

  if (!user) {
    return null;
  }

  return (
    <AuthenticationWrapper>
      <AuthenticationWrapper.Container
        {...containerProps}
        style={{
          width: '100%',
          maxWidth: '600px',
          ...containerProps?.style,
        }}
      >
        <AuthenticationWrapper.Form
          form={props.form}
          onSubmit={props.form ? props.form.handleSubmit(props.onSubmit) : props.onSubmit}
          style={{ maxWidth: '100%', width: '100%', minWidth: '100%' }}
        >
          <AuthenticationWrapper.Header>
            <AuthenticationWrapper.Title>{title}</AuthenticationWrapper.Title>
            <AuthenticationWrapper.Subtitle>{subtitle}</AuthenticationWrapper.Subtitle>
          </AuthenticationWrapper.Header>
          <div ref={callbackRef} />

          {children}
        </AuthenticationWrapper.Form>
        <Button variant="text" onClick={handleLogout} className={classes.link}>
          Logout
        </Button>
      </AuthenticationWrapper.Container>
    </AuthenticationWrapper>
  );
};

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @param {SetupPageProps<TFormValues>} props
 */
export const SetupPage = ({ method, ...props }) => (
  <MFASetupPageContextProvider method={method}>
    <SetupPageContent {...props} />
  </MFASetupPageContextProvider>
);

SetupPage.CancelButton = CancelButton;

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @param {SetupPagePropsWithoutFactorId<TFormValues>} props
 */
export const SetupPageWithoutProvider = (props) => <SetupPageContent {...props} />;

SetupPageWithoutProvider.CancelButton = CancelButton;
