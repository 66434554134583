import { z } from 'zod';

import { OTP_INPUT_OBJECT_SCHEMA } from '../../components/OTPInput';

export const verificationCodeSchema = z.object({
  verificationCode: OTP_INPUT_OBJECT_SCHEMA,
  displayName: z.string().optional(),
});

/**
 * @typedef {z.infer<typeof verificationCodeSchema>} TOTPVerificationFormType
 */

export const STEPS = ['Download & Setup', 'Verify Code'];
