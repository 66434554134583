import React from 'react';
import { Box } from '@mui/material';

import { ChristmasBanner } from './ChristmasBanner';
import { CdaClinicsJoinsCandorBanner } from './CdaClinicsJoinsCandorBanner';

/**
 * @param {React.ComponentProps<typeof Box>} props
 * @returns
 */
export const AllBanners = ({ sx, ...props }) => (
  <Box sx={{ width: '100%', mx: 'auto', display: 'flex', flexDirection: 'column', gap: '1rem', ...sx }} {...props}>
    <ChristmasBanner />
    <CdaClinicsJoinsCandorBanner />
  </Box>
);
