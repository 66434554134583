import { PhoneMultiFactorGenerator, TotpMultiFactorGenerator } from 'firebase/auth';

/**
 * Gets a display name for the MFA factor
 * @param {import('@firebase/auth').MultiFactorInfo} factor - The MFA factor info
 * @param {Object} [options] - The options for the friendly name
 * @param {boolean} [options.noDisplayName=false] - Whether to not use the display name
 * @returns {string} The display name for the factor
 */
export const getMFAFriendlyName = (factor, options = { noDisplayName: false }) => {
  const displayName = [];

  switch (factor.factorId) {
    case TotpMultiFactorGenerator.FACTOR_ID:
      displayName.push('Authenticator App');
      break;
    case PhoneMultiFactorGenerator.FACTOR_ID:
      displayName.push('Phone');
      break;
    default:
      return factor.displayName || 'Unknown Factor';
  }

  if (!options.noDisplayName && factor.displayName) {
    displayName.push(`(${factor.displayName})`);
  }

  return displayName.join(' ');
};
