import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Stack, Box, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';

import { QRCodeDisplayer } from '../../components';
import { SecretKeyDisplayer } from './SecretKeyDisplayer';

const AUTH_APPS = [
  {
    name: 'Google Authenticator',
    description: 'Simple and secure authenticator from Google',
    links: {
      ios: 'https://apps.apple.com/app/google-authenticator/id388497605',
      android: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    },
  },
  {
    name: 'Microsoft Authenticator',
    description: 'Feature-rich authenticator with cloud backup support',
    links: {
      ios: 'https://apps.apple.com/app/microsoft-authenticator/id983156458',
      android: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
    },
  },
  {
    name: 'Authy',
    description: 'Multi-device authenticator with encrypted cloud backup',
    links: {
      ios: 'https://apps.apple.com/app/authy/id494168017',
      android: 'https://play.google.com/store/apps/details?id=com.authy.authy',
    },
  },
];

const AuthAppsAccordion = () => (
  <Accordion
    elevation={0}
    sx={{ border: 1, borderColor: 'divider', borderRadius: 1, ':before': { display: 'none' } }}
    disableGutters
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="subtitle2">Recommended Authenticator Apps</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Stack spacing={2}>
        {AUTH_APPS.map((app) => (
          <Box key={app.name}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2" gutterBottom>
                {app.name}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Link href={app.links.ios} target="_blank" rel="noopener noreferrer">
                  iOS
                </Link>
                <Link href={app.links.android} target="_blank" rel="noopener noreferrer">
                  Android
                </Link>
              </Stack>
            </Stack>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {app.description}
            </Typography>
          </Box>
        ))}
      </Stack>
    </AccordionDetails>
  </Accordion>
);

export const SetupAuthenticatorStep = ({ secret, qrCode }) => (
  <Stack spacing={3} alignItems="center" width="100%">
    <Typography variant="body2" fontWeight="bold" textAlign="center">
      Scan the QR code below with your authenticator app to set up two-factor authentication
    </Typography>

    <Box
      sx={{
        width: 200,
        height: 200,
        backgroundColor: '#00000009',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
      }}
    >
      {qrCode && <QRCodeDisplayer url={qrCode} size={200} />}
    </Box>

    <AuthAppsAccordion />

    <SecretKeyDisplayer secretKey={secret?.secretKey} />
  </Stack>
);
