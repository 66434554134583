import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { Box, Button, CircularProgress, Divider, Grid2 as Grid, Stack, Typography } from '@mui/material';

import PaymentContext from '../PaymentContext';
import { AuthContext } from '../../../auth-context';
import { db, functions } from '../../../firebase-config';
import { convertToCurrency } from '../../../utils/constants';
import TillPayment from '../till-payments-ui/TillPayment';

const NOVATTI_RATE_FIXED = 0.3;
const NOVATTI_PRODUCT_RATE_PERCENTAGE = 0.02;

const getAmount = (paymentDetails) => paymentDetails?.price || 0;

const getCreditCardFee = (amount) =>
  amount > 1
    ? Math.floor(((amount + NOVATTI_RATE_FIXED) / (1 - NOVATTI_PRODUCT_RATE_PERCENTAGE) - amount) * 100) / 100
    : 0;

const showPaymentDetails = ({ loading, paymentDetails }) => !loading && Object.keys(paymentDetails).length > 0;
const showPaymentInProgress = ({ loading, paymentDetails }) => !loading && Object.keys(paymentDetails).length === 0;
const showIFrame = ({ iFrameUrl, error }) => iFrameUrl && !error;
const showIframeLoading = ({ iFrameUrl, error, shouldShowTillPaymentForm }) =>
  !iFrameUrl && !error && !shouldShowTillPaymentForm;

const styles = {
  paymentItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 4,
    '@media (max-width: 600px)': {
      marginBottom: '5px',
    },
  },
  paymentTotal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
  },
  paymentContainer: { width: '100%', alignItems: 'center', margin: 'auto' },
  iFrame: {
    width: '100%',
    height: '24rem',
    border: 0,
  },
  customPaymentLoadingContainer: {
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CustomPayment = ({ functionName, paymentCollectionName, iFrameUrlAddress }) => {
  const { user } = useContext(AuthContext);
  const { reference, showPaymentMethods, setShowPaymentMethods } = useContext(PaymentContext);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [iFrameUrl, setIFrameUrl] = useState('');
  // POTENTIAL BUG: Should initialise to null, handle it correctly. {} is a truthy value
  const [paymentDetails, setPaymentDetails] = useState({});
  const [shouldShowTillPaymentForm, setShouldShowTillPaymentForm] = useState(false);
  const unsubOrderPaymentCheck = React.useRef(/** @type {(() => void) | null} */ (null));

  const amount = getAmount(paymentDetails);
  const creditCardFee = getCreditCardFee(amount);
  const total = Math.floor((amount + creditCardFee) * 100) / 100;

  useEffect(() => {
    const getPaymentDetails = async () => {
      if (!user) {
        return;
      }

      setLoading(true);

      const patientData = (await getDoc(doc(db, 'patients', user.uid))).data();

      if (patientData?.paymentRequired?.[reference]) {
        setPaymentDetails(patientData.paymentRequired[reference]);
      }

      setLoading(false);
    };

    getPaymentDetails();

    return () => {
      if (unsubOrderPaymentCheck.current) {
        unsubOrderPaymentCheck.current();
      }
    };
  }, [reference, user]);

  const handleProceed = async () => {
    setShowPaymentMethods(true);

    if (!iFrameUrlAddress) {
      setShouldShowTillPaymentForm(true);
      return;
    }

    // FIXME: This fn expects no args, fix the context to specify what args it expects
    const { data: orderData } = (await httpsCallable(functions, functionName)({ reference })) || {};
    const retrievedIFrameUrl =
      functionName === 'novattiCreateOrderCustom_v2'
        ? orderData?.[iFrameUrlAddress]
        : orderData?.orderResponse?.[iFrameUrlAddress];
    if (!orderData || orderData?.error || !orderData.id || !retrievedIFrameUrl) {
      setError(orderData?.error || 'Error creating order. Please contact support.');
      return;
    }

    // BUG: user is possibly null @jmossesgeld
    // Save order to firestore
    await setDoc(doc(db, 'patients', user.uid, paymentCollectionName, orderData.id), orderData);

    unsubOrderPaymentCheck.current = onSnapshot(
      doc(db, 'patients', user.uid, paymentCollectionName, orderData.id),
      (snapshot) => {
        const data = snapshot.data();
        if (snapshot.data()?.status === 'paid' || data?.webhook?.result === 'OK') {
          // TODO: Handle this better, stop using window.location.reload()
          window?.location?.reload();
        }
      },
    );

    setIFrameUrl(retrievedIFrameUrl);
  };

  return (
    <Grid container>
      <Grid
        size={{
          xs: 12,
          md: showPaymentMethods ? 6 : 12,
        }}
        sx={{
          width: '100%',
          alignItems: 'center',
          padding: '25px',
          '@media (max-width: 600px)': {
            paddingBottom: !showPaymentMethods ? 3 : 0,
          },
        }}
      >
        {loading && (
          <Grid container sx={styles.customPaymentLoadingContainer}>
            <CircularProgress sx={{ margin: 'auto' }} />
          </Grid>
        )}
        {showPaymentDetails({ loading, paymentDetails }) && (
          <Grid>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
              Payment Details
            </Typography>
            <Box sx={styles.paymentItem}>
              <Typography align="left">{paymentDetails?.title || ''}</Typography>
              <Typography>{convertToCurrency(paymentDetails.price)}</Typography>
            </Box>
            <Box sx={styles.paymentItem}>
              <Typography>Card Surcharge</Typography>
              <Typography>{convertToCurrency(creditCardFee)}</Typography>
            </Box>
            <Divider />
            <Box sx={styles.paymentTotal}>
              <Typography fontWeight="bold">Total</Typography>
              <Typography fontWeight="bold">{convertToCurrency(total)}</Typography>
            </Box>
            {!showPaymentMethods && (
              <Box>
                <Button variant="contained" onClick={handleProceed} sx={{ width: '100%', maxWidth: '150px' }}>
                  Proceed
                </Button>
              </Box>
            )}
          </Grid>
        )}
        {showPaymentInProgress({ loading, paymentDetails }) && (
          <Grid>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
              Payment In Progress
            </Typography>
            <Typography align="center">
              Payment is being processed in the background. Please refresh the page or come back again later.
            </Typography>
          </Grid>
        )}
      </Grid>
      {showPaymentMethods && (
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
          sx={styles.paymentContainer}
        >
          {showIFrame({ iFrameUrl, error }) && <iframe src={iFrameUrl} title="Custom Payment" style={styles.iFrame} />}
          {showIframeLoading({ iFrameUrl, error, shouldShowTillPaymentForm }) && (
            <Grid container sx={styles.customPaymentLoadingContainer}>
              <CircularProgress sx={{ margin: 'auto' }} />
            </Grid>
          )}
          {shouldShowTillPaymentForm && <TillPayment />}
          {error && (
            <Stack alignItems="center">
              <Typography fontWeight="bold" color="primary">
                Sorry for the inconvenience.
              </Typography>
              <Typography maxWidth={360}>{error}</Typography>
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

CustomPayment.propTypes = {
  functionName: PropTypes.string.isRequired,
  paymentCollectionName: PropTypes.string.isRequired,
  iFrameUrlAddress: PropTypes.string.isRequired,
};

export default CustomPayment;
