import { z } from 'zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from '@mui/material/Select';
import { FirebaseError } from 'firebase/app';
import MenuItem from '@mui/material/MenuItem';
import { Button, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { zodResolver } from '@hookform/resolvers/zod';
import FormHelperText from '@mui/material/FormHelperText';
import { captureException as sentryCaptureException } from '@sentry/react';
import { PhoneAuthProvider, PhoneMultiFactorGenerator, TotpMultiFactorGenerator } from 'firebase/auth';

import { getMFAFriendlyName, handleFirebaseError } from '../utils';
import { AuthenticationWrapper, classes } from '../AuthenticationWrapper';
import {
  DEFAULT_OTP_INPUT_FIELD_VALUE,
  NUMBER_OF_OTP_INPUTS,
  OTP_INPUT_OBJECT_SCHEMA,
  parseOTPInputValue,
} from './OTPInput';
import { FactorSelector } from './FactorSelector';

const verificationCodeSchema = z.object({
  verificationCode: OTP_INPUT_OBJECT_SCHEMA,
  verificationId: z.string().optional(),
});

/**
 * @typedef {z.infer<typeof verificationCodeSchema>} FormType
 */

/**
 * MFA Verification Page Component
 * @param {Object} props
 * @param {import('@firebase/auth').MultiFactorResolver} props.resolver - The MFA resolver from Firebase
 * @param {() => void} props.onSuccess - Callback function to be called when the verification is successful
 * @param {boolean} [props.asChild] - Whether to render the component as a child
 */
export const MFAVerification = ({ resolver, onSuccess, asChild = false }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [selectedFactor, setSelectedFactor] = useState(
    /** @type {import('@firebase/auth').MultiFactorInfo | null} */ (resolver.hints?.[0] || null),
  );

  const form = useForm({
    resolver: zodResolver(verificationCodeSchema),
    defaultValues: {
      verificationCode: DEFAULT_OTP_INPUT_FIELD_VALUE,
    },
  });

  const { setError, watch } = form;

  /**
   * Handles the verification process
   * @param {FormType} data - The form data
   */
  const handleVerification = async (data) => {
    if (!selectedFactor) {
      setError('root', {
        message: 'No authentication method selected.',
      });
      return;
    }

    setIsVerifying(true);

    try {
      const { verificationCode, verificationId } = data;

      const parsedVerificationCode = parseOTPInputValue(verificationCode);

      let assertion;
      switch (selectedFactor.factorId) {
        case TotpMultiFactorGenerator.FACTOR_ID:
          assertion = TotpMultiFactorGenerator.assertionForSignIn(selectedFactor.uid, parsedVerificationCode);
          break;
        case PhoneMultiFactorGenerator.FACTOR_ID:
          assertion = PhoneMultiFactorGenerator.assertion(
            PhoneAuthProvider.credential(verificationId || selectedFactor.uid, parsedVerificationCode),
          );
          break;
        default:
          setError('root', {
            message: 'Unsupported authentication method.',
          });
          return;
      }

      if (!assertion) {
        return;
      }

      await resolver.resolveSignIn(assertion);

      onSuccess?.();
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleFirebaseError({ error, setError });

        return;
      }

      const sentryIssueId = sentryCaptureException(error, {
        extra: {
          issueIn: 'MFAVerification:handleVerification',
          resolverHints: resolver.hints,
          data,
          selectedFactor,
        },
      });

      setError('root', {
        type: 'sentry',
        message: `An unexpected error occurred. Please try again. Id: ${sentryIssueId}`,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const code = watch('verificationCode');

  return (
    <AuthenticationWrapper>
      <AuthenticationWrapper.Container style={{ maxWidth: 'fit-content' }} noPaper={asChild}>
        <AuthenticationWrapper.Form
          form={form}
          onSubmit={form.handleSubmit(handleVerification)}
          style={{ maxWidth: '100% !important' }}
        >
          <AuthenticationWrapper.Header>
            <AuthenticationWrapper.Title>Two-Factor Authentication Required</AuthenticationWrapper.Title>
            <AuthenticationWrapper.Subtitle>
              Please verify your identity using one of your enrolled methods
            </AuthenticationWrapper.Subtitle>
          </AuthenticationWrapper.Header>

          <Stack spacing={3} width="100%">
            {resolver.hints.length > 1 && (
              <FormControl fullWidth size="small">
                <InputLabel>Authentication Method</InputLabel>
                <Select
                  value={selectedFactor?.uid ?? ''}
                  onChange={(e) =>
                    setSelectedFactor(resolver.hints.find((factor) => factor.uid === e.target.value) || null)
                  }
                  label="Authentication Method"
                >
                  {resolver.hints.map((factor) => (
                    <MenuItem key={factor.uid} value={factor.uid}>
                      {getMFAFriendlyName(factor)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Choose how you want to verify your identity</FormHelperText>
              </FormControl>
            )}

            <FactorSelector
              factor={selectedFactor}
              resolver={resolver}
              onSubmit={form.handleSubmit(handleVerification)}
            />

            <AuthenticationWrapper.Actions>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.button}
                loading={isVerifying}
                loadingPosition="end"
                disabled={code.length !== NUMBER_OF_OTP_INPUTS}
              >
                {isVerifying ? 'Verifying' : 'Verify'}
              </Button>
            </AuthenticationWrapper.Actions>
          </Stack>
        </AuthenticationWrapper.Form>
      </AuthenticationWrapper.Container>
    </AuthenticationWrapper>
  );
};
