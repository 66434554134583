import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

import { MFAVerification } from './MFAVerification';

/**
 * @param {Object} props
 * @param {boolean} [props.nonDismissable]
 * @param {import('@firebase/auth').MultiFactorResolver | null} props.mfaResolver
 * @param {React.Dispatch<React.SetStateAction<import('@firebase/auth').MultiFactorResolver | null>>} props.setMfaResolver
 * @param {() => void} props.onSuccess
 * @returns {React.ReactNode}
 */
export const MFAVerificationDialogue = ({ nonDismissable, mfaResolver, setMfaResolver, onSuccess }) => {
  if (!mfaResolver) {
    return null;
  }

  const handleClose = () => {
    if (nonDismissable) {
      return;
    }

    setMfaResolver(null);
  };

  return (
    <Dialog
      maxWidth="md"
      open={!!mfaResolver}
      onClose={handleClose}
      slotProps={{ backdrop: { style: { backdropFilter: 'blur(10px)' } } }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <MFAVerification asChild resolver={mfaResolver} onSuccess={onSuccess} />
      </DialogContent>
    </Dialog>
  );
};
