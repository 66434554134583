import React from 'react';
import { FormProvider } from 'react-hook-form';

import { classes } from './classes';

/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @typedef {React.ComponentProps<'form'> & { form: import('react-hook-form').UseFormReturn<TFormValues> | undefined }} FormProps
 */


/**
 * @template {import('react-hook-form').FieldValues} TFormValues
 * @param {FormProps<TFormValues>} props
 */
export const Form = ({ form, children, ...props }) => {
  if (!form) {
    return (
      <form className={classes.form} {...props}>
        {children}
      </form>
    );
  }

  return (
    <FormProvider {...form}>
      <form className={classes.form} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
