import React from 'react';

import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { withPrivateRoute } from '../withPrivateRoute';

const { SUPPORT, DIRECTOR, PHARMACIST } = USER_TYPES;

const PharmacyOrdersPage = React.lazy(() =>
  import('../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyOrdersPage,
  })),
);

const PharmacyLayout = React.lazy(() =>
  import('../../../features/pharmacyDirectory/pages').then((module) => ({
    default: module.PharmacyLayout,
  })),
);

/**
 * TODO: This can be refactored... we should have better routing for this, or
 * use query params to filter the orders.
 */

/**
 * @satisfies {ExtendedRouteObject}
 */
export const ordersRoutes = {
  name: 'Orders Layout',
  type: 'layout',
  ...withPrivateRoute(<PharmacyLayout />, {
    userCanAccess: ({ userType }) => allowedUserType([SUPPORT, DIRECTOR, PHARMACIST], userType),
  }),
  children: [
    {
      name: 'Orders',
      path: 'orders',
      type: 'item',
      ...withPrivateRoute(<PharmacyOrdersPage key="current" tab="current" />, {
        userCanAccess: ({ userType }) => allowedUserType([SUPPORT, DIRECTOR, PHARMACIST], userType),
      }),
    },
    {
      name: 'Order History',
      path: 'order-history',
      type: 'item',
      ...withPrivateRoute(<PharmacyOrdersPage key="historic" tab="historic" />, {
        userCanAccess: ({ userType }) => allowedUserType([PHARMACIST], userType),
      }),
    },
  ],
};
