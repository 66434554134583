import React from 'react';
import { Outlet } from 'react-router';
import { styled } from '@mui/material';

const Root = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100vw',
  height: 'auto',
  minHeight: 'fit-content',
  maxHeight: '100dvh',
  overflowY: 'auto',
  padding: '0',
}));

const AuthenticationPageLayout = () => (
  <Root>
    <Outlet />
  </Root>
);

export default AuthenticationPageLayout;
