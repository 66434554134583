import React from 'react';
import { wrapCreateBrowserRouterV7 } from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router';

import { useAuth } from '../hooks/useAuth';
import { Loading } from '../components/layout';
import { useVitePreloadError } from './useVitePreloadError';
import { useIFrameResizeObserver } from './useIFrameResizeObserver';
import { routes } from './routes';

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV7(createBrowserRouter)(routes);

export const Router = () => {
  const { isAuthLoading } = useAuth();

  useVitePreloadError();
  useIFrameResizeObserver();

  if (isAuthLoading) {
    return <Loading sx={{ minHeight: '100vh' }} debugSection="Auth Loading" />;
  }

  return <RouterProvider router={sentryCreateBrowserRouter} />;
};
