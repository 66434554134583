import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import CancelRounded from '@mui/icons-material/CancelRounded';

const POLL_INTERVAL = 50;
const MAX_ATTEMPTS = 20;
/**
 * @param {Object} props
 * @param {React.RefObject<HTMLIFrameElement | null>} props.nodeRef
 */
const handleOnLoad = ({ nodeRef }) => {
  let pollInterval;
  const startPolling = () => {
    let attempts = 0;
    pollInterval = setInterval(() => {
      nodeRef.current?.contentDocument?.body?.setAttribute?.('data-auth-popup', 'true');

      attempts += 1;

      if (attempts >= MAX_ATTEMPTS) {
        // 1000ms => POLL_INTERVAL ms * MAX_ATTEMPTS attempts
        clearInterval(pollInterval);
      }
    }, POLL_INTERVAL);
  };

  startPolling();

  return () => {
    clearInterval(pollInterval);
  };
};

const handleResizeMessage = ({ event }) => {
  if (event.data.type !== 'resize' || !event.data.height) {
    return;
  }

  const iframe = document.querySelector('#authentication-modal-iframe');
  if (iframe && iframe instanceof HTMLIFrameElement) {
    iframe.style.height = `${event.data.height}px`;
  }
};

/**
 * @param {Object} props
 * @param {MessageEvent} props.event
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setModalOpen
 * @param {(() => void )| undefined} props.onSuccess
 */
const handleSignInSuccessMessage = ({ event, setModalOpen, onSuccess }) => {
  if (event.data?.type !== 'SIGN_IN_SUCCESS') {
    return;
  }

  setModalOpen(false);
  onSuccess?.();
};

/**
 * @param {Object} props
 * @param {MessageEvent} props.event
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setModalOpen
 * @param {(() => void )| undefined} props.onSuccess
 */
const handleMessages = ({ event, setModalOpen, onSuccess }) => {
  // Verify origin for security
  if (event.origin !== window.location.origin) {
    return;
  }

  // Handle resize message
  handleResizeMessage({ event });

  // Handle sign in success message
  handleSignInSuccessMessage({ event, setModalOpen, onSuccess });
};

/**
 * @typedef {import('react').PropsWithChildren<{
 *   modalOpen: boolean;
 *   setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
 *   mode?: 'login' | 'reauthenticate';
 *   onSuccess?: () => void;
 * }> & ({
 *   nonDismissable?: false;
 *   onCancel?: () => void;
 * } | {
 *   nonDismissable: true;
 * })} AuthenticationModalProps
 *
 * @typedef {React.FC<AuthenticationModalProps>} AuthenticationModal
 */

/**
 * @type {AuthenticationModal}
 */
export const AuthenticationModal = ({ modalOpen, setModalOpen, mode = 'login', onSuccess, ...props }) => {
  const iframeRef = useRef(/** @type {HTMLIFrameElement | null} */ (null));

  useEffect(() => {
    /**
     * @param {MessageEvent} event
     */
    const handleMessage = (event) => {
      handleMessages({ event, setModalOpen, onSuccess });
    };

    window.addEventListener('message', handleMessage);

    const clearOnLoadHandler = handleOnLoad({ nodeRef: iframeRef });

    return () => {
      window.removeEventListener('message', handleMessage);
      clearOnLoadHandler();
    };
  }, [setModalOpen, onSuccess]);

  const handleClose = () => {
    if (props.nonDismissable) {
      return;
    }

    props.onCancel?.();
    setModalOpen(false);
  };

  if (!modalOpen) {
    return null;
  }

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
        <div
          style={{
            display: props.nonDismissable ? 'none' : 'flex',
            justifyContent: 'flex-end',
            height: '40px',
            width: '100%',
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Button onClick={handleClose}>
            <CancelRounded color="primary" />
          </Button>
        </div>
        <iframe
          ref={iframeRef}
          id="authentication-modal-iframe"
          src={`${window.location.origin}/${mode}`}
          width="100%"
          title={`${mode}-frame`}
          style={{
            border: 'none',
            margin: 0,
            padding: 0,
            overflow: 'auto',
            display: 'block',
            height: 'auto',
            minHeight: '200px',
            maxHeight: '95dvh',
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
