import React from 'react';
import { multiFactor } from 'firebase/auth';
import { useLoaderData } from 'react-router';
import SecurityIcon from '@mui/icons-material/Security';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Stack, useTheme, Typography, Box, Fade } from '@mui/material';

import { isUserEnrolledInMFAMethod } from '../../utils';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';

const SMSToTOTPSection = ({ userHasPhoneMFA }) => {
  const theme = useTheme();

  if (!userHasPhoneMFA) {
    return null;
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography fontSize={18} color="primary">
        Important Change to Your Security Setup
      </Typography>
      <Typography variant="body2" align="center">
        We noticed you&apos;re currently using SMS-based two-factor authentication. As part of this security update,
        we&apos;ll:
      </Typography>

      <Fade in>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            my: 1,
            px: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <PhoneAndroidIcon sx={{ fontSize: 24 }} />
            <Typography variant="caption" align="center">
              SMS Authentication
            </Typography>
          </Box>

          <ArrowForwardIcon sx={{ color: theme.palette.primary.main, fontSize: 24, pb: 2 }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <SecurityIcon sx={{ fontSize: 24, color: theme.palette.primary.main }} />
            <Typography variant="caption" align="center">
              Authenticator App
            </Typography>
          </Box>
        </Box>
      </Fade>

      <Typography variant="body2">
        1. Removing your current SMS-based authentication
        <br />
        2. Setting up the more secure authenticator app method
      </Typography>
    </div>
  );
};

export const SecurityRequirementPage = ({ onContinue }) => {
  const theme = useTheme();
  const { user } = useLoaderData();
  const multiFactorUser = multiFactor(user);
  const hasPhoneMFA = isUserEnrolledInMFAMethod({ factorId: 'phone', multiFactorUser });

  return (
    <AuthenticationWrapper>
      <Fade in>
        <AuthenticationWrapper.Container
          style={{
            paddingBlock: theme.spacing(4),
            marginBlock: theme.spacing(4),
            width: '600px',
            maxWidth: '100dvw',
          }}
        >
          <AuthenticationWrapper.Header
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.25rem' }}
          >
            <SecurityIcon sx={{ fontSize: 32, color: theme.palette.primary.main }} />
            <AuthenticationWrapper.Title>Security Update Required</AuthenticationWrapper.Title>
            <AuthenticationWrapper.Subtitle>
              We&apos;re enhancing our security measures to better protect your account. You are required to set up
              two-factor authentication using an authenticator app.
            </AuthenticationWrapper.Subtitle>
          </AuthenticationWrapper.Header>

          <SMSToTOTPSection userHasPhoneMFA={hasPhoneMFA} />

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography fontSize={18} color="primary">
              Why the change?
            </Typography>
            <Stack spacing={1} sx={{ width: '100%', alignItems: 'center' }}>
              <Typography variant="body2" component="div">
                <span>
                  <strong>1. Enhanced Security:</strong> More resistant to interception than SMS codes
                </span>
                <br />
                <span>
                  <strong>2. Always Available:</strong> Works without cellular service or internet connection
                </span>
                <br />
                <span>
                  <strong>3. Faster Access:</strong> No waiting for SMS codes to arrive
                </span>
                <br />
                <span>
                  <strong>4. Better Protection:</strong> Immune to phone number hijacking attacks
                </span>
              </Typography>
            </Stack>
          </Box>

          <AuthenticationWrapper.Actions>
            <Button variant="contained" onClick={onContinue} endIcon={<SecurityIcon />}>
              {hasPhoneMFA ? 'Update Security Method' : 'Set Up Authentication'}
            </Button>
          </AuthenticationWrapper.Actions>
        </AuthenticationWrapper.Container>
      </Fade>
    </AuthenticationWrapper>
  );
};
