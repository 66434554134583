import React from 'react';
import { styled } from '@mui/material';

import { AuthenticationPageContextProvider } from '../AuthenticationPageContextProvider';
import { Form } from './Form';
import { Title } from './Title';
import { Header } from './Header';
import { Actions } from './Actions';
import { classes } from './classes';
import { Subtitle } from './Subtitle';
import { Container } from './Container';

const Root = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
  padding: '0',
  boxSizing: 'border-box',
  overflow: 'clip',
  overflowY: 'auto',

  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    minHeight: 'fit-content',
    height: 'fit-content',
    margin: 'auto',
    width: '100%',
    boxSizing: 'border-box',
  },

  [`& .${classes.pageContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    margin: '1rem',
    maxWidth: '400px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    boxSizing: 'border-box',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    display: 'flex',
    width: '100%',
  },

  [`& .${classes.button}`]: {
    width: 'fit-content',
  },

  [`& .${classes.link}`]: {
    align: 'center',
    textTransform: 'none',
    cursor: 'pointer',
    '&:focus': { textDecoration: 'underline' },
  },

  [`& .${classes.cancelButton}`]: {
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    paddingBlock: '0.5rem',
  },
}));

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns
 */
export const AuthenticationWrapper = ({ children }) => (
  <AuthenticationPageContextProvider>
    <Root>
      <div className={classes.container}>{children}</div>
    </Root>
  </AuthenticationPageContextProvider>
);

AuthenticationWrapper.Form = Form;
AuthenticationWrapper.Title = Title;
AuthenticationWrapper.Header = Header;
AuthenticationWrapper.Actions = Actions;
AuthenticationWrapper.Subtitle = Subtitle;
AuthenticationWrapper.Container = Container;
