import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid2 as Grid, Typography, Button, Stack, Container, Divider } from '@mui/material';

import { db } from '../../firebase-config';
import { AuthContext } from '../../auth-context';
import AdminImage from '../../media/dashboard/admin.png';
import TGAFilesImage from '../../media/dashboard/tga.png';
import HealthImage from '../../media/dashboard/health.png';
import OrdersImage from '../../media/dashboard/orders.png';
import AccountImage from '../../media/dashboard/account.png';
import HistoryImage from '../../media/dashboard/history.png';
import ConsultsImage from '../../media/dashboard/consults.png';
import PatientsImage from '../../media/dashboard/patients.png';
import CatalogueImage from '../../media/dashboard/catalogue.png';
import MessagingImage from '../../media/dashboard/messaging.png';
import PharmaciesImage from '../../media/dashboard/pharmacies.png';
import AppointmentsImage from '../../media/dashboard/appointments.png';
import PrescriptionsImage from '../../media/dashboard/prescriptions.png';
import Loading from '../layout/loading';
import { AllBanners } from '../AllBanners';
import StatusCard from './satus-card/status-card-container';

const PREFIX = 'Dashboard';

const classes = {
  section: `${PREFIX}-section`,
  treatmentImage: `${PREFIX}-treatmentImage`,
  paper: `${PREFIX}-paper`,
  paperHolder: `${PREFIX}-paperHolder`,
  selected: `${PREFIX}-selected`,
  link: `${PREFIX}-link`,
  heading: `${PREFIX}-heading`,
  divider: `${PREFIX}-divider`,
  iconButton: `${PREFIX}-iconButton`,
  button: `${PREFIX}-button`,
  gridItem: `${PREFIX}-gridItem`,
  icon: `${PREFIX}-icon`,
  container: `${PREFIX}-container`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.section}`]: {
    marginBottom: 50,
    textAlign: 'center',
  },

  [`& .${classes.treatmentImage}`]: {
    width: '100%',
    margin: 'auto',
    padding: '10px 0px',
  },

  [`& .${classes.paper}`]: {
    position: 'relative',
    padding: '20px',
    margin: '20px 20px 0px 20px',
    width: '100%',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
  },

  [`& .${classes.selected}`]: {
    borderWidth: '3px',
    borderColor: '#2AAFBB',
    padding: '10px',
    margin: '10px',
    '@media (max-width: 600px)': {
      padding: '5px',
      margin: '5px',
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
  },

  [`& .${classes.heading}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.divider}`]: {
    marginBottom: '10px',
  },

  [`& .${classes.iconButton}`]: {
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    padding: '20px 15px',
    backgroundColor: '#fff',
    color: '#0E6D81',
    textTransform: 'none',
    borderRadius: 10,
    width: 160,
    '&:hover': {
      backgroundColor: '#F6F9FC',
    },
  },

  [`& .${classes.gridItem}`]: {
    marginTop: 40,
  },

  [`& .${classes.icon}`]: {
    fontSize: 70,
    color: '#2AAFBB',
  },

  [`&.${classes.container}`]: {
    paddingBottom: 5,
  },
}));

const NotificationsBanner = ({ bannerData }) => {
  if (bannerData && bannerData.title) {
    return (
      <>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Notifications
        </Typography>

        <AllBanners />

        <StatusCard statusObj={bannerData} />
        <Divider
          className={classes.divider}
          variant="middle"
          sx={{
            opacity: '0.6',
          }}
        />
      </>
    );
  }

  return <AllBanners />;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, userType, catalogues } = useContext(AuthContext);

  const isMounted = useRef(false);

  const [bannerData, setBannerData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    async function getData() {
      const userDoc = await getDoc(doc(db, 'patients', user.uid));
      const userData = userDoc.data();

      if (isMounted.current) {
        setBannerData(userData?.banner);
        setDataLoaded(true);
      }

      const { payment, eula } = userData?.acceptedAgreements || {};

      if (['pharmacist', 'supplier'].includes(userType) && (!payment || !eula)) {
        setBannerData({ title: 'awaiting agreement acceptance', link: '/account-details' });
      }
    }
    getData();

    return () => {
      isMounted.current = false;
    };
  }, [user, userType]);

  if (!dataLoaded) {
    return <Loading debugSection="Dashboard" />;
  }

  return (
    <StyledContainer className={classes.container} maxWidth="md">
      <NotificationsBanner bannerData={bannerData} />
      <Grid container className={classes.section}>
        {userType === 'patient' && (
          <>
            <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
              <Button variant="contained" className={classes.button} onClick={() => navigate('/consults')}>
                <Stack direction="column" alignItems="center" gap="1px">
                  <img src={ConsultsImage} className={classes.icon} alt="Consults" />
                  <Typography variant="body1" fontWeight="600">
                    Consults
                  </Typography>
                </Stack>
              </Button>
            </Grid>
            <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
              <Button variant="contained" className={classes.button} onClick={() => navigate('/purchase')}>
                <Stack direction="column" alignItems="center" gap="1px">
                  <img src={PrescriptionsImage} className={classes.icon} alt="Prescriptions" />
                  <Typography variant="body1" fontWeight="600">
                    Purchase
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          </>
        )}
        {userType !== 'pharmacist' && userType !== 'supplier' && userType !== 'patient' && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/patients')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={PatientsImage} className={classes.icon} alt="Patients" />
                <Typography variant="body1" fontWeight="600">
                  Patients
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'doctor' || userType === 'nurse' || userType === 'support') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/assigned-patients')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={ConsultsImage} className={classes.icon} alt="Assigned" />
                <Typography variant="body1" fontWeight="600">
                  Assigned
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'support' || userType === 'director' || userType === 'pharmacist') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/orders')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={OrdersImage} className={classes.icon} alt="Orders" />
                <Typography variant="body1" fontWeight="600">
                  Orders
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {userType === 'pharmacist' && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/pharmacy-stock')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={AdminImage} className={classes.icon} alt="Stock" />
                <Typography variant="body1" fontWeight="600">
                  Stock
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {catalogues?.length > 0 && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/catalogue')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={CatalogueImage} className={classes.icon} alt="Catalogue" />
                <Typography variant="body1" fontWeight="600">
                  Catalogue
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'patient' || userType === 'pharmacist') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() =>
                navigate(
                  userType === 'support' || userType === 'pharmacist' ? '/messages/list/patients' : '/messages',
                  { replace: true },
                )
              }
            >
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={MessagingImage} className={classes.icon} alt="Messaging" />
                <Typography variant="body1" fontWeight="600">
                  Messaging
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {userType !== 'pharmacist' && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/pharmacies')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={PharmaciesImage} className={classes.icon} alt="Pharmacies" />
                <Typography variant="body1" fontWeight="600">
                  Pharmacies
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {userType === 'patient' && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/appointments')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={AppointmentsImage} className={classes.icon} alt="Appointments" />
                <Typography variant="body1" fontWeight="600">
                  Appointments
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'patient' || userType === 'pharmacist') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => navigate(userType === 'patient' ? '/purchase-history' : '/order-history')}
            >
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={HistoryImage} className={classes.icon} alt="History" />
                <Typography variant="body1" fontWeight="600">
                  {`${userType === 'patient' ? 'Purchase' : 'Order'} History`}{' '}
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'support' ||
          userType === 'doctor' ||
          userType === 'director' ||
          userType === 'pharmacist' ||
          userType === 'supplier') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/tga')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={TGAFilesImage} className={classes.icon} alt="TGA Files" />
                <Typography variant="body1" fontWeight="600">
                  TGA Files
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {userType === 'patient' && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/medical-info')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={HealthImage} className={classes.icon} alt="Health" />
                <Typography variant="body1" fontWeight="600">
                  Medical Info
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        {(userType === 'director' || userType === 'nurse' || userType === 'support') && (
          <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate('/admin-control')}>
              <Stack direction="column" alignItems="center" gap="1px">
                <img src={AdminImage} className={classes.icon} alt="Admin" />
                <Typography variant="body1" fontWeight="600">
                  Admin
                </Typography>
              </Stack>
            </Button>
          </Grid>
        )}
        <Grid className={classes.gridItem} size={{ xs: 6, md: 3 }}>
          <Button variant="contained" className={classes.button} onClick={() => navigate('/account-details')}>
            <Stack direction="column" alignItems="center" gap="1px">
              <img src={AccountImage} className={classes.icon} alt="Account" />
              <Typography variant="body1" fontWeight="600">
                Account
              </Typography>
            </Stack>
          </Button>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dashboard;
