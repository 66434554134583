import React from 'react';
import { Outlet } from 'react-router';

import { RedirectToNewAuthPage } from './RedirectToNewAuthPage';

const AuthActionSuccessPage = React.lazy(() =>
  import('../../../../features/authentication/actions').then((module) => ({
    default: module.AuthActionSuccessPage,
  })),
);

const AuthActionErrorPage = React.lazy(() =>
  import('../../../../features/authentication/actions').then((module) => ({
    default: module.AuthActionErrorPage,
  })),
);

const ResetPasswordPage = React.lazy(() =>
  import('../../../../features/authentication/actions').then((module) => ({
    default: module.ResetPasswordPage,
  })),
);

const AuthActionPage = React.lazy(() =>
  import('../../../../features/authentication/actions').then((module) => ({
    default: module.AuthActionPage,
  })),
);

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const authenticationRoutes = [
  {
    name: 'Auth Landing Page',
    path: 'auth',
    type: 'item',
    element: <Outlet />,
    children: [
      {
        name: 'Auth Action Page',
        path: '',
        type: 'item',
        index: true,
        element: <AuthActionPage key="auth-action-page" />,
      },
      {
        name: 'Auth Landing Page Success',
        path: 'success',
        type: 'item',
        element: <AuthActionSuccessPage />,
      },
      {
        name: 'Auth Landing Page Error',
        path: 'error',
        type: 'item',
        element: <AuthActionErrorPage />,
      },
      {
        name: 'Reset Password',
        path: 'reset-password',
        type: 'item',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    // This is for backwards compatibility with the old auth landing page
    name: 'Auth Landing Page Redirect',
    path: 'auth-landing-page',
    type: 'item',
    element: <RedirectToNewAuthPage />,
  },
];
