import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress, Divider, Grid2 as Grid, Typography, Checkbox, Modal } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { captureMessage as sentryCaptureMessage } from '@sentry/react';
import PropTypes from 'prop-types';

import { AuthContext } from '../../../auth-context';
import { SERVICES } from '../paymentHelpers';
import { convertToCurrency } from '../../../utils/constants';
import { functions } from '../../../firebase-config';
import InformationModal from '../../prescriptions/information-modal';
import { scriptPriceTranslator } from '../../../utils/form-translators';
import PromoContainer from './PromoContainer';
import PaymentContext from '../PaymentContext';

const getIsConsulation = (products) => [SERVICES.BOOKING_FEE, SERVICES.CONSULT].includes(products?.[0]?.name);
const getIsButtonDisabled = ({ termsRead, isConsultation, scriptMode, pharmacyConsentRead, isPharmacyPending }) =>
  (!termsRead && !isConsultation) || (scriptMode && !pharmacyConsentRead) || (!scriptMode && isPharmacyPending);

const PREFIX = 'PaymentDetails';

const classes = {
  root: `${PREFIX}-root`,
  paymentDetails: `${PREFIX}-paymentDetails`,
  paymentItem: `${PREFIX}-paymentItem`,
  paymentTotal: `${PREFIX}-paymentTotal`,
  termsText: `${PREFIX}-termsText`,
  modalGrid: `${PREFIX}-modalGrid`,
  shippingAlert: `${PREFIX}-shippingAlert`,
  promoCode: `${PREFIX}-promoCode`,
};

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  padding: '20px',

  [`& .${classes.paymentDetails}`]: {
    margin: '1rem 0',
    padding: '1rem 0',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    height: '100%',
    width: '100%',
    '@media (max-width: 600px)': {
      marginBottom: 0,
    },
  },

  [`& .${classes.paymentItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '3rem',
    '@media (max-width: 600px)': {
      marginBottom: '5px',
    },
  },

  [`& .${classes.paymentTotal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
  },

  [`& .${classes.termsText}`]: {
    marginBottom: 20,
  },

  [`& .${classes.modalGrid}`]: {
    margin: 40,
    '@media (max-width: 600px)': {
      margin: 30,
    },
  },

  [`& .${classes.shippingAlert}`]: {
    margin: '-20px 0px 20px 0px',
  },

  [`& .${classes.promoCode}`]: {
    display: 'flex',
    alignSelf: 'start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    margin: '5px 0px',
  },
}));

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
}));

const LowerCaseButton = styled(Button)(() => ({
  textTransform: 'none',
}));

// Needs a thorough refactor
const PaymentDetails = () => {
  const {
    checkoutData,
    treatmentsToRead,
    selectedTreatments,
    handlePaymentButton,
    isCheckoutLoading,
    isPaymentLoading,
    termsRead,
    description,
    setCheckoutData,
    handleTermsRead,
    scriptMode,
    formName,
    shippingAlert,
    promoCode,
    pharmacyConsentRead,
    isPickup,
    isPharmacyPending,
    treatmentTranslator,
    isPromoLoading,
    setIsPromoLoading,
    showPaymentMethods,
  } = useContext(PaymentContext);
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);

  const { creditCardFee, discount, discountCode, appliedCredit, finalAmount, shippingCharge, gst, products } =
    checkoutData || {};
  const isConsultation = getIsConsulation(products);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsPromoLoading(true);
    const getCheckoutData = async () => {
      try {
        const { data } = await httpsCallable(
          functions,
          'getCheckoutData_v2',
        )({
          selectedTreatments: scriptMode ? [scriptPriceTranslator[formName]] : selectedTreatments,
          discountCode: promoCode,
          userId: user?.uid,
          isPickup,
        });
        if (data?.error) {
          throw new Error(data?.error);
        }

        setCheckoutData(data);
        setIsPromoLoading(false);
      } catch ({ message }) {
        setIsError(true);
        sentryCaptureMessage(message, { level: 'error', user: { selectedTreatments, scriptMode, formName } });
      }
    };
    if (selectedTreatments) {
      getCheckoutData();
    }
  }, [selectedTreatments, setCheckoutData, scriptMode, formName, promoCode, user, isPickup, setIsPromoLoading]);

  // get products name, quanty and total price display
  const checkoutProducts = () => {
    const newData = [];
    products.forEach((item) => {
      const newItem = {
        stripePrice: '',
        data: '',
        qty: 0,
      };
      const foundIndex = newData.findIndex((x) => x.stripePrice === item.stripePrice);
      if (foundIndex === -1) {
        newItem.stripePrice = item.stripePrice;
        newItem.data = item;
        newItem.qty = 1;
        newData.push(newItem);
      } else {
        newData[foundIndex].qty += 1;
      }
    });
    return newData;
  };

  if (isError) {
    return (
      <StyledGrid>
        <Typography fontWeight="bold" color="secondary">
          Oh snap there is an Error! Please refresh the page.
          <br />
          Sorry for the inconvenience.
        </Typography>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid
      data-testid="payment-details-modal"
      size={{
        xs: 12,
        md: showPaymentMethods ? 6 : 12,
      }}
      sx={{
        '@media (max-width: 600px)': {
          paddingBottom: !showPaymentMethods ? 3 : 0,
        },
      }}
    >
      <Typography variant="h5" gutterBottom>
        Payment Details
      </Typography>
      {products && Object.keys(treatmentTranslator || {}).length !== 0 && (
        <>
          <Box className={classes.paymentDetails}>
            {checkoutProducts().map((product) => {
              const price = product.data.gst ? product.data.price / 1.1 : product.data.price;
              return (
                <Box key={product.stripePrice} className={classes.paymentItem}>
                  <Typography>{`${scriptMode ? 'Script Admin Fee' : (treatmentTranslator[product.stripePrice]?.item ?? description)} x ${product.qty}`}</Typography>
                  <Typography>{convertToCurrency(price * product.qty)}</Typography>
                </Box>
              );
            })}
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
            <Box className={classes.paymentItem}>
              <Typography>Shipping Fee</Typography>
              <Typography>{convertToCurrency(shippingCharge)}</Typography>
            </Box>
            <CreditCardFee creditCardFee={creditCardFee} className={classes.paymentItem} />
            <Divider />
            <Box className={classes.paymentItem}>
              <Typography>GST</Typography>
              <Typography>{convertToCurrency(gst || 0)}</Typography>
            </Box>
            {discount !== 0 && (
              <Box className={classes.paymentItem}>
                <Typography color="darkred">Discount Code: {discountCode}</Typography>
                <Typography color="darkred">{`- ${convertToCurrency(discount)}`}</Typography>
              </Box>
            )}
            <PromoContainer isPromoLoading={isPromoLoading} setIsPromoLoading={setIsPromoLoading} />
            <Divider />
            {appliedCredit !== 0 && (
              <>
                <Box className={classes.paymentItem}>
                  <Typography color="green">Credit To Be Applied</Typography>
                  <Typography color="green">{convertToCurrency(-appliedCredit)}</Typography>
                </Box>
                {parseInt(finalAmount, 10) === 1 && (
                  <Typography variant="caption" alignSelf="center" color="darkred">
                    Maximum discount applied, the rest of your credit can be used on the next purchase.
                  </Typography>
                )}
                <Divider
                  sx={{
                    opacity: '0.6',
                  }}
                />
              </>
            )}
            <Box className={classes.paymentTotal}>
              <Typography fontWeight="bold">Total</Typography>
              <Typography fontWeight="bold">{convertToCurrency(finalAmount)}</Typography>
            </Box>
          </Box>
          {!isCheckoutLoading && (
            <>
              {!isConsultation && treatmentsToRead.length !== 0 && (
                <>
                  <Typography className={classes.termsText} variant="body1" gutterBottom>
                    <Checkbox checked={termsRead || false} onClick={handleTermsRead} />I have read the{' '}
                    <LowerCaseButton
                      variant="text"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      classes={{
                        root: classes.root,
                      }}
                    >
                      treatment information
                    </LowerCaseButton>
                  </Typography>
                  <StyledModal open={modalOpen}>
                    <Grid className={classes.modalGrid}>
                      <InformationModal setModalOpen={setModalOpen} selectedTreatments={treatmentsToRead} />
                    </Grid>
                  </StyledModal>
                  {shippingAlert && (
                    <Typography className={classes.shippingAlert} variant="body2" color="secondary" gutterBottom>
                      {shippingAlert}
                    </Typography>
                  )}
                </>
              )}
              <Box>
                <Button
                  variant="contained"
                  sx={{ maxWidth: '150px' }}
                  disabled={getIsButtonDisabled({
                    termsRead,
                    isConsultation,
                    scriptMode,
                    pharmacyConsentRead,
                    isPharmacyPending,
                  })}
                  onClick={handlePaymentButton}
                >
                  {isPaymentLoading ? 'Loading...' : 'Proceed'}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {!products && <CircularProgress />}
    </StyledGrid>
  );
};

const CreditCardFee = ({ creditCardFee, className }) => {
  if (!creditCardFee) {
    return null;
  }

  return (
    <Box className={className}>
      <Typography>Card Surcharge</Typography>
      <Typography>{convertToCurrency(creditCardFee)}</Typography>
    </Box>
  );
};

CreditCardFee.propTypes = {
  creditCardFee: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default PaymentDetails;
