import React from 'react';
import { TotpMultiFactorGenerator, PhoneMultiFactorGenerator } from 'firebase/auth';

import { SMSFactor, TOTPFactor } from './factors';

/**
 * @param {Object} props
 * @param {import('@firebase/auth').MultiFactorInfo | null} props.factor
 * @param {import('@firebase/auth').MultiFactorResolver} props.resolver
 * @param {() => void} props.onSubmit
 */
export const FactorSelector = ({ factor, resolver, onSubmit }) => {
  if (!factor) {
    return null;
  }

  switch (factor.factorId) {
    case TotpMultiFactorGenerator.FACTOR_ID:
      return <TOTPFactor onSubmit={onSubmit} />;
    case PhoneMultiFactorGenerator.FACTOR_ID:
      return <SMSFactor factor={factor} session={resolver.session} onSubmit={onSubmit} />;
    default:
      return null;
  }
};
