import { Snackbar } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { AuthenticationPageContext } from './AuthenticationPageContext';

const SNACKBAR_AUTO_HIDE_DURATION = 3000;

export const AuthenticationPageContextProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
  });

  /**
   * @type {(message: string) => void}
   */
  const handleSnackbar = useCallback(
    (message) => {
      setSnackbar({
        message,
        open: true,
      });
    },
    [setSnackbar],
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbar({
      message: '',
      open: false,
    });
  }, [setSnackbar]);

  const value = useMemo(
    () => ({
      snackbar: handleSnackbar,
    }),
    [handleSnackbar],
  );

  return (
    <AuthenticationPageContext.Provider value={value}>
      {children}
      <Snackbar
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        message={snackbar.message}
        sx={{ textAlign: 'center', '&>div': { minWidth: 'fit-content' } }}
      />
    </AuthenticationPageContext.Provider>
  );
};
