import { z } from 'zod';
import {
  parseOTPInputValue,
  OTP_INPUT_OBJECT_SCHEMA,
  OTP_INPUT_OBJECT_SCHEMA_OR_EMPTY,
} from '../../components/OTPInput';

export const smsSchema = z
  .object({
    phoneNumber: z
      .string()
      .min(10, 'Phone number must be at least 10 digits')
      .regex(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number with country code (e.g. +1234567890)'),
    verificationCode: OTP_INPUT_OBJECT_SCHEMA.or(OTP_INPUT_OBJECT_SCHEMA_OR_EMPTY),
  })
  .refine(
    (data) => {
      const verificationCode = parseOTPInputValue(data.verificationCode);

      // If the verification code is empty, we need to check if the user has entered the code
      if (verificationCode.length === 0) {
        return OTP_INPUT_OBJECT_SCHEMA_OR_EMPTY.safeParse(data.verificationCode).success;
      }

      return OTP_INPUT_OBJECT_SCHEMA.safeParse(data.verificationCode).success;
    },
    {
      message: 'Verification code is invalid',
      path: ['verificationCode'],
    },
  );
