/**
 * Checks if SMS MFA is mandatory for the given user type and current user status.
 * @returns {boolean} True if SMS MFA is mandatory, false otherwise.
 */
export const isSMSMFAMandatory = () => false;

/**
 * Checks if the user needs to setup SMS MFA
 * @param {Object} params
 * @param {import('firebase/auth').MultiFactorUser} params.multiFactorUser
 * @returns {boolean}
 */
export const userNeedsSMSMFASetup = ({ multiFactorUser }) => {
  if (!isSMSMFAMandatory()) {
    return false;
  }

  return !multiFactorUser.enrolledFactors.some((factor) => factor.factorId === 'phone');
};
