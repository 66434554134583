import React from 'react';

import { Loadable } from '../../components/navigation/Loadable';
import { LoginPage, PasswordResetPage, RegisterPage } from '../../features/authentication';

const AccountCreation = React.lazy(() => import('../../components/AccountCreation/AccountCreation'));

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const unauthenticatedUserRoutes = [
  {
    name: 'Authentication Page Wrapper',
    type: 'layout',
    element: Loadable(React.lazy(() => import('../../features/authentication/AuthenticationPageLayout'))),
    children: [
      {
        name: 'Login',
        path: 'login',
        type: 'item',
        element: <LoginPage />,
      },
      {
        name: 'Register',
        path: 'register',
        type: 'item',
        element: <RegisterPage />,
      },
      {
        name: 'Password Reset',
        path: 'reset-password',
        type: 'item',
        element: <PasswordResetPage />,
      },
    ],
  },
  {
    name: 'Pharmacy Creation',
    path: 'pharmacy-creation',
    type: 'item',
    element: <AccountCreation isPharmacy />,
  },
  {
    name: 'Doctor Creation',
    path: 'doctor-creation',
    type: 'item',
    element: <AccountCreation isDoctor />,
  },
  {
    name: 'Supplier Creation',
    path: 'supplier-creation',
    type: 'item',
    element: <AccountCreation isSupplier />,
  },
];
