import Check from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography, Button, Stack, Box } from '@mui/material';

import { useAuthenticationPageContext } from '../../useAuthenticationPageContext';

const LENGTH_OF_SHORTENDED_SECRET_KEY = 12;

/**
 * @param {string} secretKey
 * @returns {string}
 */
const shortendedSecretKey = (secretKey) => {
  if (!secretKey) {
    return '';
  }

  return `${secretKey.slice(0, LENGTH_OF_SHORTENDED_SECRET_KEY / 2)}...${secretKey.slice(
    -LENGTH_OF_SHORTENDED_SECRET_KEY / 2,
  )}`;
};

/**
 * @param {Object} props
 * @param {string | undefined} props.secretKey
 */
export const SecretKeyDisplayer = ({ secretKey }) => {
  const [copied, setCopied] = useState(false);
  const { snackbar } = useAuthenticationPageContext();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const handleCopy = async () => {
    if (!secretKey) {
      return;
    }

    try {
      await navigator.clipboard.writeText(secretKey);
      setCopied(true);
    } catch (error) {
      snackbar('Failed to copy secret key');
    }
  };

  if (!secretKey) {
    return null;
  }

  return (
    <Stack spacing={1} alignItems="center" textAlign="center">
      <Typography variant="subtitle2" fontWeight="bold">
        Can&apos;t scan the QR code?
      </Typography>
      <Typography variant="body2">Copy the code below and paste it into your authenticator app</Typography>
      <Button variant="outlined" size="small" startIcon={copied ? <Check /> : <ContentCopyIcon />} onClick={handleCopy}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{secretKey}</Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>{shortendedSecretKey(secretKey)}</Box>
      </Button>
    </Stack>
  );
};
