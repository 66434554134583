import { isSMSMFAMandatory } from './userNeedsSMSMFASetup';
import { isTOTPMFAMandatory } from './userNeedsTOTPMFASetup';

/**
 * Determines if a MFA method is mandatory for a user.
 * Ordered by priority of MFA methods.
 *
 * @param {Object} params
 * @param {FactorId} params.method
 * @param {import("../../../utils/constants").UserType | ""} params.userType
 * @returns {boolean}
 */
export const determineIfMFAMethodIsMandatory = ({ method, userType }) => {
  switch (method) {
    case 'totp':
      return isTOTPMFAMandatory({ userType });
    case 'phone':
      return isSMSMFAMandatory();
    default:
      return false;
  }
};
