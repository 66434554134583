import { useContext } from 'react';
import { MFASetupPageContext } from './MFASetupPageContext';

export const useMFASetupPageContext = () => {
  const context = useContext(MFASetupPageContext);

  if (!context) {
    throw new Error('useMFASetupPageContext must be used within a MFASetupPageContext');
  }

  return context;
};
