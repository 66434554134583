import { useNavigate } from 'react-router';
import React, { useCallback, useMemo, useState } from 'react';

import { auth } from '../../../../firebase-config';
import { AuthenticationModal } from '../../components';
import { MFASetupPageContext } from './MFASetupPageContext';

export const MFASetupPageContextProvider = ({ method, children }) => {
  const navigate = useNavigate();
  const [isReauthenticating, setIsReauthenticating] = useState(false);

  const handleLogout = useCallback(async () => {
    await auth.signOut();
    navigate(`/login?redirect=/mfa-setup/${method}`, { replace: true });
  }, [navigate, method]);

  const reauthenticate = useCallback(async () => {
    setIsReauthenticating(true);
  }, []);

  const onReauthenticateSuccess = useCallback(async () => {
    await auth.currentUser?.reload();
  }, []);

  /**
   * @satisfies {import('./MFASetupPageContext').MFASetupPageContextType}
   */
  const value = useMemo(() => ({ method, handleLogout, reauthenticate }), [method, handleLogout, reauthenticate]);

  return (
    <MFASetupPageContext.Provider key={isReauthenticating ? 'reauthenticating' : 'not-reauthenticating'} value={value}>
      {children}
      <AuthenticationModal
        nonDismissable
        modalOpen={isReauthenticating}
        setModalOpen={setIsReauthenticating}
        onSuccess={onReauthenticateSuccess}
        mode="reauthenticate"
      />
    </MFASetupPageContext.Provider>
  );
};
