import { useEffect } from 'react';

export const useVitePreloadError = () => {
  useEffect(() => {
    const handlePreloadError = () => {
      window?.location?.reload?.();
    };

    window.addEventListener('vite:preloadError', handlePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', handlePreloadError);
    };
  }, []);
};
