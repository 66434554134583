import React from 'react';
import { getAuth } from 'firebase/auth';
import { Navigate, redirect } from 'react-router';

import { LoginPage } from '../../features/authentication';
import { TOTPSetupPage, SMSSetupPage } from '../../features/authentication/mfa';
import { SharedBaseLayout } from '../layouts';
import { authenticationRoutes, baseFormsRoutes } from './base-routes';

/**
 * @type {import('react-router').LoaderFunction}
 */
const mfaSetupLoader = async ({ request }) => {
  const user = getAuth().currentUser;

  if (!user) {
    const url = new URL(request.url);
    const redirectTo = url.pathname;

    throw redirect(`/login?redirect=${redirectTo}`);
  }

  // Revalidate the user's session so that we can check if they have MFA enabled
  await user.getIdToken();

  return {
    user,
  };
};

/**
 * These are the routes shared between all users:
 * - Unauthenticated users
 * - Authenticated users
 *   - Verified users
 *   - Unverified users
 * @satisfies {ExtendedRouteObject[]}
 */
export const sharedRoutes = [
  ...authenticationRoutes,
  {
    name: 'MFA Setup',
    path: 'mfa-setup',
    type: 'parent',
    children: [
      {
        name: 'Setup MFA Index',
        path: '',
        index: true,
        type: 'item',
        element: <Navigate replace to="dashboard" />,
      },
      {
        name: 'Setup TOTP MFA',
        path: /** @satisfies {FactorId} */ ('totp'),
        type: 'item',
        loader: mfaSetupLoader,
        element: <TOTPSetupPage />,
      },
      {
        name: 'Setup SMS MFA',
        path: /** @satisfies {FactorId} */ ('phone'),
        type: 'item',
        loader: mfaSetupLoader,
        element: <SMSSetupPage />,
      },
    ],
  },
  {
    name: 'Shared Layout With Authentication Layout',
    type: 'layout',
    element: <SharedBaseLayout />,
    children: [...baseFormsRoutes],
  },
  {
    name: 'Reauthenticate',
    path: 'reauthenticate',
    type: 'item',
    element: <LoginPage noPaper reauthenticate />,
  },
];
