import React from 'react';
import { useFormContext } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, LinearProgress, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import { USER_TYPES } from '../../../utils/constants';

/**
 * @typedef {'weak' | 'medium' | 'strong'} PasswordStrength
 * @typedef {'error' | 'warning' | 'success'} StrengthColor
 */

/**
 * @typedef {Object} StrengthCriteria
 * @property {string} id - Id of the criteria
 * @property {function(string): boolean} test - Function to test password criteria
 * @property {string} hint - Hint for the criteria
 */

/** @type {StrengthCriteria[]} */
const STRENGTH_CRITERIA = [
  {
    id: 'length',
    test: (password) => password.length >= 8,
    hint: 'At least 8 characters',
  },
  {
    id: 'number',
    test: (password) => /\d/.test(password),
    hint: 'At least 1 number',
  },
  {
    id: 'case',
    test: (password) => /[a-z]/.test(password) && /[A-Z]/.test(password),
    hint: 'At least 1 lowercase and 1 uppercase letter',
  },
  {
    id: 'special',
    test: (password) => /[^A-Za-z0-9]/.test(password),
    hint: 'At least 1 special character',
  },
];

/** @type {{ weak: { max: number, color: StrengthColor }, medium: { max: number, color: StrengthColor }, strong: { max: number, color: StrengthColor } }} */
const STRENGTH_THRESHOLDS = {
  weak: { max: 50, color: 'error' },
  medium: { max: 75, color: 'warning' },
  strong: { max: 100, color: 'success' },
};

/**
 * Evaluates password against criteria and returns strength and criteria status
 * @param {string} password - The password to evaluate
 * @returns {{ strength: number, criteriaStatus: Array<{ id: string, hint: string, passed: boolean }> }}
 */
const evaluatePassword = (password) => {
  if (!password) {
    return {
      strength: 0,
      criteriaStatus: STRENGTH_CRITERIA.map(({ id, hint }) => ({ id, hint, passed: false })),
    };
  }

  const criteriaStatus = STRENGTH_CRITERIA.map((criteria) => ({
    id: criteria.id,
    hint: criteria.hint,
    passed: criteria.test(password),
  }));

  const strength = criteriaStatus.reduce((total, { passed }) => (passed ? total + 1 : total), 0);

  const strengthOutOf100 = Math.round((strength / STRENGTH_CRITERIA.length) * 100);

  return { strength: strengthOutOf100, criteriaStatus };
};

/**
 * Gets the strength details based on the calculated strength score
 * @param {number} strength - The calculated strength score
 * @returns {{ label: PasswordStrength, color: StrengthColor }} Strength details
 */
const getStrengthDetails = (strength) => {
  if (strength < STRENGTH_THRESHOLDS.weak.max) {
    return { label: 'weak', color: STRENGTH_THRESHOLDS.weak.color };
  }
  if (strength < STRENGTH_THRESHOLDS.medium.max) {
    return { label: 'medium', color: STRENGTH_THRESHOLDS.medium.color };
  }
  return { label: 'strong', color: STRENGTH_THRESHOLDS.strong.color };
};

/**
 * @typedef {Object} PasswordStrengthIndicatorProps
 * @property {string} [formId='password'] - The key of the password field in the form
 */

/**
 * A component that displays password strength with a progress bar and requirements
 * @param {PasswordStrengthIndicatorProps} props
 * @returns {React.ReactNode}
 */
export const PasswordStrengthIndicator = ({ formId = 'password' }) => {
  const { user, userType } = useAuth();
  /** @type {import('react-hook-form').UseFormReturn} */
  const { watch } = useFormContext();

  const password = watch(formId);
  const { strength, criteriaStatus } = evaluatePassword(password);
  const { label, color } = getStrengthDetails(strength);
  const [expanded, setExpanded] = React.useState(false);

  // TODO: Remove when we are ready to enforce and show this to everyone
  if (!password || !user || userType !== USER_TYPES.DIRECTOR) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        variant="determinate"
        value={strength}
        color={color}
        sx={{
          height: 4,
          borderRadius: '1rem',
        }}
      />
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 'none',
          '&:before': { display: 'none' },
          backgroundColor: 'transparent',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: 0,
            minHeight: 'auto',
            '& .MuiAccordionSummary-content': { margin: 0 },
          }}
        >
          <Typography
            variant="caption"
            color={color}
            sx={{
              display: 'block',
              mb: 1,
              textTransform: 'capitalize',
              fontWeight: 'medium',
            }}
          >
            Password Strength: {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {criteriaStatus.map(({ id, hint, passed }) => (
            <Box
              key={id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 0.5,
              }}
            >
              {passed ? (
                <CheckCircleIcon sx={{ fontSize: 16, color: 'success.main' }} />
              ) : (
                <CancelIcon sx={{ fontSize: 16, color: 'secondary.main' }} />
              )}
              <Typography variant="caption" color={passed ? 'success.main' : 'secondary.main'}>
                {hint}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
