import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { USER_TYPES, allowedUserType } from '../../../utils/constants';
import { withPrivateRoute } from '../withPrivateRoute';

const { NURSE, DIRECTOR, SUPPORT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const adminRoutes = {
  name: 'Admin Control',
  path: 'admin-control',
  type: 'item',
  userCanAccess: ({ userType }) => allowedUserType([NURSE, DIRECTOR, SUPPORT], userType),
  children: [
    {
      index: true,
      type: 'item',
      ...withPrivateRoute(Loadable(React.lazy(() => import('../../../components/admin/AdminContainer'))), {
        userCanAccess: ({ userType }) => allowedUserType([NURSE, DIRECTOR, SUPPORT], userType),
      }),
    },
    {
      name: 'Main Control Panel',
      path: ':page',
      type: 'item',
      ...withPrivateRoute(Loadable(React.lazy(() => import('../../../components/admin/AdminComponents'))), {
        userCanAccess: ({ userType }) => allowedUserType([NURSE, DIRECTOR, SUPPORT], userType),
      }),
    },
  ],
};
