import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { useAuth } from '../../hooks/useAuth';

const redirectToLogin = () => {
  const currentPath = window.location.pathname;

  if (currentPath === '/') {
    return <Navigate replace to="/login" />;
  }

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('redirect', currentPath);

  return <Navigate replace to={`/login?${searchParams.toString()}`} />;
};

export const UnverifiedUserLayout = () => {
  const { user, emailVerificationDetails } = useAuth();

  const { isEmailVerified } = emailVerificationDetails;

  if (!user) {
    return redirectToLogin();
  }

  if (isEmailVerified) {
    return <Navigate replace to="/dashboard" />;
  }

  return <Outlet />;
};
