import { USER_TYPES } from '../../../utils/constants';

/**
 * @type {import('../../../utils/constants').UserType[]}
 */
const USER_TYPES_WITH_MANDATORY_TOTP_MFA = [
  USER_TYPES.DOCTOR,
  USER_TYPES.SUPPORT,
  USER_TYPES.NURSE,
  USER_TYPES.DIRECTOR,
  USER_TYPES.PHARMACIST,
  USER_TYPES.SUPPLIER,
];

const isMFARequiredInEnvironment = () =>
  import.meta.env.VITE_APP_FIREBASE_CONFIG_PROJECT_ID === 'candor-react' ||
  import.meta.env.VITE_APP_MFA_MANDATORY_REQUIRED === 'true';

/**
 * Checks if TOTP 2FA/MFA is mandatory for the given user type and current user status.
 * @param {Object} params - The parameters object.
 * @param {import('../../../utils/constants').UserType | ""} params.userType - The type of the user.
 * @returns {boolean} True if TOTP 2FA is mandatory, false otherwise.
 */
export const isTOTPMFAMandatory = ({ userType }) =>
  !!userType && USER_TYPES_WITH_MANDATORY_TOTP_MFA.includes(userType) && isMFARequiredInEnvironment();

/**
 * Checks if the user needs to setup TOTP
 * @param {Object} params
 * @param {import('../../../utils/constants').UserType | ""} params.userType
 * @param {import('firebase/auth').MultiFactorUser} params.multiFactorUser
 * @returns {boolean}
 */
export const userNeedsTOTPMFASetup = ({ userType, multiFactorUser }) => {
  if (
    !isTOTPMFAMandatory({
      userType,
    })
  ) {
    return false;
  }

  return !multiFactorUser.enrolledFactors.some((factor) => factor.factorId === 'totp');
};
