import React from 'react';
import { useNavigate, useRouteError } from 'react-router';
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/react';

import { Loading, ErrorFallback } from '../components/layout';

const MODULE_ERRORS = [
  'importing a module script failed',
  'error loading dynamically imported module',
  'failed to fetch dynamically imported module',
];

const MAX_RETRY_ATTEMPTS = 1;

/**
 * @param {unknown} error
 * @returns {boolean}
 */
const isErrorDynamicImportError = (error) => {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  if (!('message' in error) || typeof error.message !== 'string') {
    return false;
  }

  const errorMessage = error.message;

  return MODULE_ERRORS.some((moduleError) => errorMessage.toLowerCase().includes(moduleError));
};

/**
 * Handles the "TypeError: Importing a module script failed." issue.
 * Refreshes the page to fix the issue.
 * @param {Object} params
 * @param {unknown} params.error
 * @returns {boolean} Whether the error was handled.
 */
const handleOutOfDateModuleError = ({ error }) => {
  if (!isErrorDynamicImportError(error)) {
    return false;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const refreshAttempts = Number(searchParams.get('refreshAttempts') ?? 0);

  if (refreshAttempts >= MAX_RETRY_ATTEMPTS) {
    return false;
  }

  const url = new URL(window.location.href);
  url.searchParams.set('refreshAttempts', (refreshAttempts + 1).toString());

  // Refresh the page to fetch the latest version of the module.
  window.location.href = url.toString();

  return true;
};

/**
 *
 * @param {Object} props
 * @param {"verified" | "unverified" | "unauthenticated" | "authenticated" | "shared"} props.type - The route that the error occurred on.
 * @returns
 */
export const ErrorHandler = ({ type }) => {
  const [hasCompletedHandling, setHasCompletedHandling] = React.useState(false);
  const error = useRouteError();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (handleOutOfDateModuleError({ error })) {
      sentryCaptureMessage('Out of date module error', { level: 'warning', tags: { type }, extra: { error } });
      setHasCompletedHandling(true);

      return;
    }

    sentryCaptureException(error, { tags: { type } });
    setHasCompletedHandling(true);
  }, [error, type]);

  if (!hasCompletedHandling) {
    return <Loading debugSection="ErrorHandler" />;
  }

  return (
    <ErrorFallback
      resetError={() => {
        navigate('/');
      }}
    />
  );
};
