import React from 'react';
import { Navigate } from 'react-router';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { withPrivateRoute } from '../withPrivateRoute';

const { PATIENT, SUPPLIER } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const messagesRoutes = {
  name: 'Messages',
  path: 'messages',
  type: 'parent',
  ...withPrivateRoute(undefined, {
    userCanAccess: ({ userType }) => !allowedUserType([SUPPLIER], userType),
  }),
  children: [
    {
      name: 'Message Selection',
      index: true,
      path: '',
      type: 'item',
      // FIXME: This component needs to be refactored and improved. It's props are technically required... but because of the way it's implemented, this (lazy loading w/ no props) doesn't cause an error.
      element: Loadable(React.lazy(() => import('../../../components/messenger/messages-selection'))),
    },
    {
      name: 'Message Thread',
      path: ':id',
      type: 'layout',
      element: Loadable(React.lazy(() => import('../../../components/messenger/messages-container'))),
    },
    {
      name: 'Chat Lists',
      path: 'list',
      type: 'parent',
      children: [
        {
          index: true,
          path: '',
          type: 'item',
          element: <Navigate replace to=".." />,
        },
        {
          name: 'Pharmacist Chats',
          path: 'pharmacists',
          type: 'item',
          element: Loadable(React.lazy(() => import('../../../components/messenger/chat-list-presentation'))),
        },
        {
          name: 'Doctor Chats',
          path: 'doctors',
          type: 'item',
          ...withPrivateRoute(
            Loadable(React.lazy(() => import('../../../components/messenger/chat-list-presentation'))),
            {
              userCanAccess: ({ userType }) => !allowedUserType([PATIENT], userType),
            },
          ),
        },
        {
          name: 'Patient Chats',
          path: 'patients',
          type: 'item',
          ...withPrivateRoute(
            Loadable(React.lazy(() => import('../../../components/messenger/chat-list-presentation'))),
            {
              userCanAccess: ({ userType }) => !allowedUserType([PATIENT], userType),
            },
          ),
        },
        {
          path: '*',
          type: 'item',
          element: <Navigate replace to="../.." />,
        },
      ],
    },
  ],
};
