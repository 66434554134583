import { z } from 'zod';

export const NUMBER_OF_OTP_INPUTS = 6;

export const DEFAULT_OTP_INPUT_VALUE = '';

/**
 * @type {Array<{ value: string }>}
 */
export const DEFAULT_OTP_INPUT_FIELD_VALUE = Array(NUMBER_OF_OTP_INPUTS).fill({ value: DEFAULT_OTP_INPUT_VALUE });

export const OTP_INPUT_OBJECT_SCHEMA = z.array(z.object({ value: z.string().length(1) })).length(NUMBER_OF_OTP_INPUTS, {
  message: `The verification code must be ${NUMBER_OF_OTP_INPUTS} characters.`,
});

export const OTP_INPUT_OBJECT_SCHEMA_OR_EMPTY = z.array(z.object({ value: z.string() })).length(NUMBER_OF_OTP_INPUTS, {
  message: `The verification code must be ${NUMBER_OF_OTP_INPUTS} characters.`,
});
