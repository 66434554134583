import React from 'react';

import { classes } from './classes';

/**
 * @param {React.ComponentProps<"div">} props
 */
export const Header = ({ children, ...props }) => (
  <div className={classes.header} {...props}>
    {children}
  </div>
);
