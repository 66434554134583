import React from 'react';
import { multiFactor } from 'firebase/auth';
import { Navigate, Outlet } from 'react-router';
import { Box, CssBaseline, styled } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import Navbar from '../../components/navigation/navbar';
import { Sidebar } from '../../components/navigation/Sidebar';
import { getNextMFAFactorIdNeedingSetup } from '../../features/authentication';

const DRAWER_WIDTH = 220;

const StyledMainContent = styled('div')({
  flex: 1,
  minWidth: '100%',
  overflow: 'auto',
  paddingBottom: '1rem',
});

export const AuthenticatedUserLayout = () => {
  const { user, userType, handleLogout } = useAuth();

  const currentPath = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set('redirect', currentPath);

  // If the user is not authenticated, redirect them to the login page
  if (!user) {
    return <Navigate replace to={`/login?${searchParams.toString()}`} />;
  }

  const nextMFAFactorIdNeedingSetup = getNextMFAFactorIdNeedingSetup({ userType, multiFactorUser: multiFactor(user) });

  if (nextMFAFactorIdNeedingSetup) {
    return <Navigate replace to={`/mfa-setup/${nextMFAFactorIdNeedingSetup}`} state={{ isAutomatedRedirect: true }} />;
  }

  return (
    <Box
      id="main-layout"
      sx={{ display: 'flex', height: '100dvh', overscrollBehavior: 'none', minHeight: '100dvh', width: '100%' }}
    >
      <CssBaseline />

      <Box
        component="nav"
        sx={{
          width: { sm: DRAWER_WIDTH },
          flexShrink: { sm: 0 },
          '@media (max-width: 1000px)': {
            display: 'none',
          },
        }}
      >
        <Sidebar handleLogoutClose={handleLogout} open={undefined} onClose={undefined} from={undefined} />
      </Box>

      <Box
        component="main"
        sx={{
          width: {
            xs: '100%',
            sm: `calc(100% - ${DRAWER_WIDTH}px)`,
            '@media (max-width: 1000px)': {
              width: '100%',
            },
          },
          minHeight: '100dvh',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          overscrollBehavior: 'none',
          pt: 2,
          gap: 1,
        }}
      >
        <Navbar />
        <StyledMainContent id="main-content-authenticated-user-layout">
          <Outlet />
        </StyledMainContent>
      </Box>
    </Box>
  );
};
