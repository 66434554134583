import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { zodResolver } from '@hookform/resolvers/zod';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router';
import { Typography, Button, TextField, Alert } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { auth } from '../../../firebase-config';
import { AuthenticationWrapper, classes } from '../AuthenticationWrapper';
import { useAuthenticationPageContext } from '../useAuthenticationPageContext';

const resetSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
});

/**
 * @typedef {z.infer<typeof resetSchema>} FormType
 */

/**
 * Handles the error logic for the submission of the password reset form
 * @param {Object} params
 * @param {Error} params.error
 * @param {import('react-hook-form').UseFormSetError<FormType>} params.setError
 */
const handleOnSubmitErrors = ({ error, setError }) => {
  if (!(error instanceof FirebaseError)) {
    const sentryErrorId = sentryCaptureException(error, {
      level: 'error',
      extra: { issueIn: 'handleOnSubmitError' },
    });

    setError('root', {
      type: 'sentry',
      message: `An unexpected error occurred. Try again later. Id: ${sentryErrorId}`,
    });

    return;
  }

  if (error.code === 'auth/invalid-email') {
    setError('email', { message: 'This email is not valid' });
  } else {
    const sentryErrorId = sentryCaptureException(error, {
      level: 'warning',
      extra: { issueIn: 'handlePasswordResetRequest' },
    });

    setError('root', {
      type: 'sentry',
      message: `Unknown error. Please contact support. Id: ${sentryErrorId}`,
    });
  }
};

export const PasswordResetPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { snackbar } = useAuthenticationPageContext();
  const [isSuccess, setIsSuccess] = React.useState(false);

  const email = String(location.state?.email || '');

  const form = useForm({
    resolver: zodResolver(resetSchema),
    defaultValues: {
      email,
    },
  });

  const {
    register,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = form;

  /**
   * Handles the submission of the password reset form
   * @param {FormType} data - The data from the password reset form
   */
  const onSubmit = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data.email.trim().toLowerCase());
      setIsSuccess(true);
      snackbar('Password reset link sent');
    } catch (error) {
      handleOnSubmitErrors({ error, setError });
    }
  };

  return (
    <AuthenticationWrapper>
      <AuthenticationWrapper.Container>
        <AuthenticationWrapper.Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <AuthenticationWrapper.Header>
            <AuthenticationWrapper.Title>Reset Password</AuthenticationWrapper.Title>

            <AuthenticationWrapper.Subtitle>
              Enter your email address and we&#39;ll email a link to reset your password
            </AuthenticationWrapper.Subtitle>
          </AuthenticationWrapper.Header>

          <TextField
            size="small"
            label="Email"
            autoComplete="username"
            variant="outlined"
            className={classes.input}
            {...register('email')}
            error={!!errors.email}
            disabled={isSuccess}
            helperText={errors.email?.message}
          />

          {errors.root?.message && (
            <Typography variant="body2" color="error" align="center">
              {errors.root?.message}
            </Typography>
          )}

          <AuthenticationWrapper.Actions>
            {isSuccess ? (
              <Alert severity="success">Password reset link sent</Alert>
            ) : (
              <Button
                type="submit"
                className={classes.button}
                loading={isSubmitting}
                loadingPosition="end"
                disabled={isSubmitSuccessful}
                variant="contained"
                color="primary"
              >
                {isSubmitting ? 'Sending' : 'Send Reset Link'}
              </Button>
            )}
          </AuthenticationWrapper.Actions>
        </AuthenticationWrapper.Form>
        <Button variant="text" onClick={() => navigate('/login')} className={classes.link} disabled={isSubmitting}>
          Back to Sign In
        </Button>
      </AuthenticationWrapper.Container>
    </AuthenticationWrapper>
  );
};
