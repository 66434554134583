import React from 'react';
import { Paper } from '@mui/material';

import { classes } from './classes';

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentProps<"div"> & {noPaper?: boolean}>>}
 */
export const Container = React.forwardRef(({ children, noPaper, ...props }, ref) => {
  const Component = noPaper ? 'div' : Paper;

  return (
    <Component className={classes.pageContainer} {...props} ref={ref}>
      {children}
    </Component>
  );
});

Container.displayName = 'Container';
