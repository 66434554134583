import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { multiFactor } from 'firebase/auth';

import { useAuth } from '../../../../hooks/useAuth';
import { getNextMFAFactorIdNeedingSetup } from '../../utils';
import { useMFASetupPageContext } from './useMFASetupPageContext';

/**
 * @param {Object} props
 * @param {import('firebase/auth').User} props.user
 * @param {boolean} [props.disabled]
 */
export const CancelButton = ({ user, disabled = false }) => {
  const navigate = useNavigate();
  const { userType } = useAuth();
  const { method } = useMFASetupPageContext();

  const nextMFAFactorIdNeedingSetup = getNextMFAFactorIdNeedingSetup({ userType, multiFactorUser: multiFactor(user) });

  if (nextMFAFactorIdNeedingSetup === method) {
    return null;
  }

  return (
    <Button disabled={disabled} type="button" variant="outlined" onClick={() => navigate('/account-details/security')}>
      Cancel
    </Button>
  );
};
