import { determineIfMFAMethodIsMandatory } from './determineIfMFAMethodIsMandatory';

/**
 * @satisfies {FactorId[]}
 */
const MFA_METHODS = ['totp', 'phone'];

/**
 * Returns the mandatory MFA Factor Ids for a user.
 *
 * @param {Object} params
 * @param {import("../../../utils/constants").UserType | ""} params.userType
 * @returns {FactorId[]}
 */
export const getMandatoryMFAFactorIds = ({ userType }) =>
  MFA_METHODS.filter((method) => determineIfMFAMethodIsMandatory({ method, userType }));
