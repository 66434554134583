import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';

const MedicinalCannabisForm = React.lazy(
  () => import('../../../components/form/standard-forms/medicinal-cannabis-form'),
);

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const baseFormsRoutes = [
  {
    name: 'Medicinal Cannabis Form',
    path: 'medicinal-cannabis-form',
    type: 'item',
    element: <MedicinalCannabisForm />,
  },
  {
    name: 'Hair Loss Form',
    path: 'hair-loss-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/hair-loss-form'))),
  },
  {
    name: 'Weight Loss Form',
    path: 'weight-loss-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/weight-loss-form'))),
  },
  {
    name: 'Sleep Form',
    path: 'sleep-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/sleep-form'))),
  },
  {
    name: 'Cold Sores Form',
    path: 'cold-sores-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/cold-sores-form'))),
  },
  {
    name: 'Herpes Form',
    path: 'herpes-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/herpes-form'))),
  },
  {
    name: 'Acne Form',
    path: 'acne-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/acne-form'))),
  },
  {
    name: 'Ageing Form',
    path: 'ageing-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/ageing-form'))),
  },
  {
    name: 'Contraceptive Pill Form',
    path: 'contraceptive-pill-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/contraceptive-pill-form'))),
  },
  {
    name: 'Allergies Form',
    path: 'allergies-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/allergies-form'))),
  },
  {
    name: 'Erectile Dysfunction Form',
    path: 'erectile-dysfunction-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/erectile-dysfunction-form'))),
  },
  {
    name: 'Skincare Form',
    path: 'skincare-form',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/skincare-form'))),
  },
  ...(import.meta.env.VITE_ENVIRONMENT === 'development' || import.meta.env.VITE_ENVIRONMENT === 'staging'
    ? /** @type {ExtendedRouteObject[]} */ ([
        {
          name: 'Nicotine Form',
          path: 'nicotine-form',
          type: 'item',
          element: Loadable(React.lazy(() => import('../../../components/form/standard-forms/nicotine-form'))),
        },
      ])
    : []),
];
