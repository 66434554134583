import React, { useMemo } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { USER_TYPES } from '../../utils/constants';
import { Banner } from './Banner';

const START_MONTH = 11; // December
const START_DAY = 2;
const END_MONTH = 0; // January
const END_DAY = 2;

/**
 * @param {Date} date - The date to check
 * @returns {boolean} Whether the date is within the Christmas season
 *
 * Note: If the dates to include more that two months, then the logic will have
 * to be changed slightly.
 */
const isDateWithinRange = (date) => {
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  if (currentMonth === START_MONTH) {
    return currentDay >= START_DAY;
  }

  if (currentMonth === END_MONTH) {
    return currentDay <= END_DAY;
  }

  return false;
};

export const ChristmasBanner = () => {
  const { userType } = useAuth();

  const isWithinDateRange = useMemo(() => {
    const today = new Date();
    return isDateWithinRange(today);
  }, []);

  if (userType !== USER_TYPES.PATIENT || !isWithinDateRange) {
    return null;
  }

  return (
    <Banner
      title="Christmas Pharmacy Closures"
      description="As the Christmas season approaches, please be aware that some pharmacies may have altered operating hours or may be closed during this period. We encourage you to plan ahead and check with your chosen pharmacy to confirm their availability over Christmas."
    />
  );
};
