import { getMandatoryMFAFactorIds } from './getMandatoryMFAFactorIds';

/**
 * Gets the next MFA factor that needs to be setup for the user.
 * @param {Object} params
 * @param {import("../../../utils/constants").UserType | ""} params.userType
 * @param {import('firebase/auth').MultiFactorUser} params.multiFactorUser
 * @returns {FactorId | undefined}
 */
export const getNextMFAFactorIdNeedingSetup = ({ userType, multiFactorUser }) => {
  const mandatoryFactorIds = getMandatoryMFAFactorIds({ userType });

  if (mandatoryFactorIds.length === 0) {
    return undefined;
  }

  const enrolledMethods = multiFactorUser.enrolledFactors.map((factor) => factor.factorId);

  // Get the first required method that the user is not enrolled in
  return mandatoryFactorIds.find((factorId) => !enrolledMethods.includes(factorId));
};
