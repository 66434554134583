import { z } from 'zod';
import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { TextField, Box, Button, useMediaQuery, Stack } from '@mui/material';

import { NUMBER_OF_OTP_INPUTS, OTP_INPUT_OBJECT_SCHEMA } from './constants';

const baseSchema = z.object({
  verificationCode: OTP_INPUT_OBJECT_SCHEMA,
});

/**
 * @typedef {z.infer<typeof baseSchema>} FormType
 */

/**
 * Handles the change event for the OTP input
 * @param {Object} params
 * @param {React.ChangeEvent<HTMLInputElement>} params.event
 * @param {number} params.index
 * @param {import('react-hook-form').UseFieldArrayUpdate<FormType>} params.update
 * @param {(() => void )| undefined} params.onLastInput
 */
const handleOTPInputOnChange = async ({ event, index, update, onLastInput }) => {
  const { value } = event.target;

  if (value === '') {
    return;
  }

  // If pasting a number, split it into an array and update all fields
  if (value.length > 1) {
    const pastedValues = value.split('').slice(0, NUMBER_OF_OTP_INPUTS - index);
    pastedValues.forEach((digit, i) => {
      update(index + i, { value: digit });
    });

    const lastIndex = index + pastedValues.length - 1;

    const nextInput = /** @type {HTMLInputElement | null} */ (
      document.querySelector(`input#mfa-verification-code-${lastIndex + 1}`)
    );

    if (nextInput) {
      nextInput.focus();
    }

    if (lastIndex === NUMBER_OF_OTP_INPUTS - 1) {
      onLastInput?.();
    }

    return;
  }

  update(index, { value });

  // Move to next input if value is entered
  // We have to use setTimeout to avoid collision with the autoFocus
  if (value && index < NUMBER_OF_OTP_INPUTS - 1) {
    setTimeout(() => {
      const nextInputToSelect = document.querySelector(`input#mfa-verification-code-${index + 1}`);
      if (nextInputToSelect && nextInputToSelect instanceof HTMLInputElement) {
        nextInputToSelect.focus();
      }
    }, 0);
    return;
  }

  onLastInput?.();
};

/**
 * Handles the keydown event for the OTP input
 * @param {Object} params
 * @param {React.KeyboardEvent<HTMLInputElement>} params.event
 * @param {number} params.index
 * @param {import('react-hook-form').UseFieldArrayUpdate<FormType>} params.update
 * @returns {void}
 */
const handleOTPInputOnKeyDown = ({ event, index, update }) => {
  if (event.key !== 'Backspace') {
    return;
  }

  update(index, { value: '' });

  const previousInput = /** @type {HTMLInputElement | null} */ (
    document.querySelector(`input#mfa-verification-code-${index - 1}`)
  );

  if (previousInput) {
    previousInput.focus();
  }
};

/**
 * Checks if clipboard content is a 6 digit code
 * @param {string} text
 * @returns {boolean}
 */
const isValidOTPCode = (text) => /^\d{6}$/.test(text);

const ClearButton = ({ update }) => {
  /** @type {import('react-hook-form').UseFormReturn<FormType>} */
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const fields = watch('verificationCode');
  const length = fields.reduce((acc, field) => acc + field.value.length, 0);

  const handleClearClick = () => {
    fields.forEach((_, index) => {
      update(index, { value: '' });
    });

    // Focus the first input after clearing
    const firstInput = document.querySelector('input#mfa-verification-code-0');

    if (firstInput instanceof HTMLInputElement) {
      firstInput.focus();
    }
  };

  if (length !== NUMBER_OF_OTP_INPUTS || Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Button variant="outlined" color="error" size="small" onClick={handleClearClick}>
      Clear
    </Button>
  );
};

/**
 * OTPInput component
 * @param {Object} props
 * @param {() => void} [props.onLastInput]
 * @returns {React.ReactElement}
 */
export const OTPInput = ({ onLastInput }) => {
  const { control, setError } = /** @type {import('react-hook-form').UseFormReturn<FormType>} */ (useFormContext());
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { fields, update } = useFieldArray({
    control,
    name: 'verificationCode',
    rules: { minLength: NUMBER_OF_OTP_INPUTS },
  });

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (!isValidOTPCode(text)) {
        setError('root', { message: 'Invalid OTP code' });

        return;
      }

      text.split('').forEach((digit, i) => {
        update(i, { value: digit });
      });

      const element = /** @type {HTMLInputElement | null} */ (
        document.querySelector(`input#mfa-verification-code-${NUMBER_OF_OTP_INPUTS - 1}`)
      );

      if (element) {
        element.focus();
      }

      if (text.length === NUMBER_OF_OTP_INPUTS) {
        onLastInput?.();
      }
    } catch (error) {
      console.error('Failed to read clipboard:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {fields.map((field, index) => (
          <TextField
            key={field.id}
            {...field}
            autoFocus={index === 0}
            size="small"
            {...(index === 0 && { autoComplete: 'one-time-code' })}
            sx={{ textAlign: 'center' }}
            id={`mfa-verification-code-${index}`}
            slotProps={{
              input: {
                onKeyDown: (/** @type {React.KeyboardEvent<HTMLInputElement>} */ event) =>
                  handleOTPInputOnKeyDown({ event, index, update }),
                onChange: (/** @type {React.ChangeEvent<HTMLInputElement>} */ event) =>
                  handleOTPInputOnChange({ event, index, update, onLastInput }),
                slotProps: {
                  input: {
                    sx: {
                      textAlign: 'center',
                      width: '2rem',
                      padding: '0.25rem !important',
                      alignItems: 'center',
                    },
                  },
                },
                sx: {
                  width: '2rem',
                },
              },
            }}
          />
        ))}
      </Box>
      <Stack direction="column" gap={1} width="100%">
        {isSmallScreen && (
          <Button variant="outlined" size="small" onClick={handlePasteClick} startIcon={<ContentPasteIcon />}>
            Paste code from clipboard
          </Button>
        )}
        <ClearButton update={update} />
      </Stack>
    </Box>
  );
};
