import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { InfoOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { Card, CardContent, Typography, IconButton, Collapse, useTheme, alpha } from '@mui/material';

/**
 * @typedef {Omit<React.ComponentProps<typeof Card>, 'onClick'> & {
 *   title: string;
 *   description: string;
 *   storageKey?: string;
 *   colour?: 'info' | 'warning' | 'error' | 'success';
 * }} BannerProps
 */

/**
 * @param {string} storageKey
 * @returns {string}
 */
const getStorageKey = (storageKey) => `${storageKey}-banner-expanded`;

/**
 * @param {BannerProps} props - Component props
 */
export const Banner = ({ title, description, colour = 'info', storageKey, sx, ...props }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(
    storageKey ? localStorage.getItem(getStorageKey(storageKey)) !== 'false' : true,
  );

  useEffect(() => {
    if (!storageKey) {
      return;
    }

    localStorage.setItem(getStorageKey(storageKey), expanded ? 'true' : 'false');
  }, [expanded, storageKey]);

  const handleToggleExpanded = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <Card
      sx={{
        width: '100%',
        cursor: 'pointer',
        backgroundColor: alpha(theme.palette[colour].light, 0.1),
        ...sx,
      }}
      onClick={handleToggleExpanded}
      {...props}
    >
      <CardContent
        sx={{ padding: 1, paddingInline: 2, paddingBottom: expanded ? '1rem !important' : '0.5rem !important' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <InfoOutlined fontSize="small" />
            <Typography variant="body2" fontWeight="600">
              {title}
            </Typography>
          </Stack>
          <IconButton onClick={handleToggleExpanded}>
            <KeyboardArrowDown
              style={{
                transition: 'transform 0.1s ease-in-out',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
        </div>
        <Collapse in={expanded}>
          <Typography variant="body2">{description}</Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
};
