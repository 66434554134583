import React from 'react';

import { QRCodeSVG } from '../QRCode';

/**
 * @typedef {Object} QRCodeDisplayerProps
 * @property {string} url - The URL to encode in the QR code
 * @property {string} [title] - The title of the QR code
 * @property {number} [size=200] - The size of the QR code
 * @property {string} [bgColor='#ffffff'] - The background color of the QR code
 * @property {string} [fgColor='#000000'] - The foreground color of the QR code
 * @property {'L' | 'M' | 'Q' | 'H'} [level='M'] - The error correction level of the QR code
 * @property {string} [logoImage] - The image to display in the center of the QR code
 * @property {number} [logoWidth=50] - The width of the logo image
 * @property {number} [logoHeight=50] - The height of the logo image
 */

/**
 * @param {QRCodeDisplayerProps} props
 * @returns {React.ReactNode}
 */
export const QRCodeDisplayer = ({
  url,
  size = 200,
  bgColor = '#ffffff',
  fgColor = '#000000',
  level = 'M',
  logoImage,
  logoWidth = 50,
  logoHeight = 50,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <QRCodeSVG
      value={url}
      size={size}
      bgColor={bgColor}
      fgColor={fgColor}
      level={level}
      className="rounded-md"
      imageSettings={
        logoImage
          ? {
              src: logoImage,
              x: undefined,
              y: undefined,
              height: logoHeight,
              width: logoWidth,
              excavate: true,
            }
          : undefined
      }
    />
  </div>
);
