import { createContext } from 'react';

/**
 * @typedef {Object} MFASetupPageContextType
 * @property {FactorId} method - The method of MFA setup
 * @property {() => Promise<void>} handleLogout - The function to handle the logout
 * @property {() => void} reauthenticate - The function to reauthenticate the user
 */

/**
 * @type {React.Context<MFASetupPageContextType | undefined>}
 */
export const MFASetupPageContext = createContext(/** @type {MFASetupPageContextType | undefined} */ (undefined));
