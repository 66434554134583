import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Stack, TextField } from '@mui/material';

import { OTPInput } from '../../components';
import { NUMBER_OF_OTP_INPUTS } from '../../components/OTPInput';

/**
 * @param {Object} props
 * @param {() => void} props.onSubmit
 */
export const VerificationStep = ({ onSubmit }) => {
  /** @type {import('react-hook-form').UseFormReturn<import('./constants').TOTPVerificationFormType>} */
  const form = useFormContext();

  const {
    formState: { errors },
  } = form;

  return (
    <Stack spacing={3} alignItems="center" width="100%">
      <Typography variant="body2" align="center" gutterBottom>
        On your authenticator app, you should see a code for the newly created Candor App. Copy the code and paste it
        below.
      </Typography>
      <Stack spacing={1} alignItems="center" width="100%" pt={1} sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" align="center">
          Display Name
        </Typography>
        <TextField
          size="small"
          placeholder="E.g., Google Authenticator"
          {...form.register('displayName')}
          error={!!errors.displayName}
          helperText={errors.displayName?.message || 'Enter a helpful name for this authenticator app (optional)'}
        />
      </Stack>
      <Stack spacing={1} alignItems="center" width="100%">
        <Typography variant="subtitle1" align="center">
          Verification Code
        </Typography>

        <OTPInput onLastInput={onSubmit} />

        <Typography variant="caption" color="text.secondary" align="center">
          Enter the {NUMBER_OF_OTP_INPUTS}-digit code from your authenticator app
        </Typography>

        {errors.verificationCode && (
          <Typography color="error" variant="caption">
            {errors.verificationCode.message}
          </Typography>
        )}

        {errors.root?.message && (
          <Typography color="error" variant="caption">
            {errors.root.message}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
