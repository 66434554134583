import { useContext } from 'react';
import { AuthenticationPageContext } from './AuthenticationPageContext';

export const useAuthenticationPageContext = () => {
  const context = useContext(AuthenticationPageContext);

  if (!context) {
    throw new Error('useAuthenticationPageContext must be used within a AuthenticationPageContext');
  }

  return context;
};
