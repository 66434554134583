import { useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from 'react-router';
import { TotpMultiFactorGenerator, multiFactor } from 'firebase/auth';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Button, Stack, useTheme, Stepper, Step, StepLabel, Fade } from '@mui/material';

import { useAuth } from '../../../../hooks/useAuth';
import { Loading } from '../../../../components/layout';
import { handleFirebaseError, isUserEnrolledInMFAMethod } from '../../utils';
import { AuthenticationWrapper, classes } from '../../AuthenticationWrapper';
import { DEFAULT_OTP_INPUT_FIELD_VALUE, NUMBER_OF_OTP_INPUTS, parseOTPInputValue } from '../../components/OTPInput';
import { addSuccessful2FAToActivityLog } from '../addSuccessful2FAToActivityLog';
import { SetupPage, SetupPageWithoutProvider, useMFASetupPageContext, MFASetupPageContextProvider } from '../SetupPage';
import { VerificationStep } from './VerificationStep';
import { STEPS, verificationCodeSchema } from './constants';
import { SetupAuthenticatorStep } from './SetupAuthenticatorStep';
import { SecurityRequirementPage } from './SecurityRequirementPage';

/**
 * @param {Object} props
 * @param {number} props.activeStep
 * @param {import('firebase/auth').TotpSecret | null} props.secret
 * @param {string} props.qrCode
 * @param {() => void} props.onSubmit
 */
const ActiveStep = ({ activeStep, secret, qrCode, onSubmit }) => {
  if (activeStep === 0) {
    return <SetupAuthenticatorStep secret={secret} qrCode={qrCode} />;
  }

  return <VerificationStep onSubmit={onSubmit} />;
};

const ActiveStepActions = ({ activeStep, setActiveStep, user, isEnrolling, handleNext, code }) => {
  if (activeStep === 0) {
    return (
      <>
        <SetupPage.CancelButton user={user} disabled={isEnrolling} />
        <Button onClick={handleNext} className={classes.button} variant="contained" color="primary">
          Next
        </Button>
      </>
    );
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setActiveStep(0)}>
        Back
      </Button>
      <Button
        type="submit"
        className={classes.button}
        variant="contained"
        color="primary"
        loading={isEnrolling}
        loadingPosition="end"
        disabled={parseOTPInputValue(code).length !== NUMBER_OF_OTP_INPUTS}
      >
        {isEnrolling ? 'Setting up' : 'Verify and Enable'}
      </Button>
    </>
  );
};

const TOTPSetupPageContent = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { reauthenticate } = useMFASetupPageContext();

  const [secret, setSecret] = useState(/** @type {import('firebase/auth').TotpSecret | null} */ (null));
  const [qrCode, setQrCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [showInformationPage, setShowInformationPage] = useState(!!location.state?.isAutomatedRedirect);

  const form = useForm({
    resolver: zodResolver(verificationCodeSchema),
    defaultValues: {
      verificationCode: DEFAULT_OTP_INPUT_FIELD_VALUE,
    },
  });

  const {
    setError,
    watch,
    formState: { isSubmitting },
  } = form;

  useEffect(() => {
    const generateSecret = async () => {
      if (!user) {
        return;
      }

      setIsLoading(true);
      try {
        const multiFactorUser = multiFactor(user);

        const userAlreadyHasTOTP = isUserEnrolledInMFAMethod({ factorId: 'totp', multiFactorUser });

        if (userAlreadyHasTOTP) {
          navigate('/dashboard', { replace: true });
          return;
        }

        // If user has phone MFA, unenroll them
        const hasPhoneMFA = isUserEnrolledInMFAMethod({ factorId: 'phone', multiFactorUser });
        if (hasPhoneMFA) {
          const phoneFactor = multiFactorUser.enrolledFactors.find((factor) => factor.factorId === 'phone');
          if (phoneFactor) {
            await multiFactorUser.unenroll(phoneFactor);
          }
        }

        const multiFactorSession = await multiFactorUser.getSession();

        const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
        setSecret(totpSecret);

        const totpUri = totpSecret.generateQrCodeUrl(
          user.email || 'user',
          import.meta.env.VITE_APP_NAME || 'Candor Medical',
        );

        setQrCode(totpUri);
      } catch (error) {
        if (error instanceof FirebaseError) {
          handleFirebaseError({ error, setError, handleRequiresRecentLogin: reauthenticate });
          return;
        }

        setError('root', {
          message: 'Failed to generate TOTP secret. Please try again.',
        });
      } finally {
        setIsLoading(false);
      }
    };

    generateSecret();
  }, [user, navigate, setError, reauthenticate]);

  /**
   * Handles the verification of the TOTP code
   * @param {import('./constants').TOTPVerificationFormType} data - The verification code data
   */
  const onSubmit = async (data) => {
    if (!secret || !user) {
      return;
    }

    const { verificationCode, displayName } = data;

    try {
      const parsedVerificationCode = parseOTPInputValue(verificationCode);
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(secret, parsedVerificationCode);

      await multiFactor(user).enroll(multiFactorAssertion, displayName?.trim() || 'Authenticator');

      await addSuccessful2FAToActivityLog({ userId: user.uid, factorId: 'totp' });

      const searchParams = new URLSearchParams();
      const redirect = searchParams.get('redirect');

      if (redirect) {
        searchParams.delete('redirect');
        navigate(`${redirect}?${searchParams.toString()}`);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleFirebaseError({
          error,
          setError,
          handleRequiresRecentLogin: reauthenticate,
        });

        return;
      }

      const sentryIssueId = sentryCaptureException(error, {
        level: 'error',
        extra: {
          issueIn: 'TOTPSetupPage:handleVerification',
          data,
        },
      });

      setError('root', {
        type: 'sentry',
        message: `Failed to enroll in TOTP. Please try again. Id: ${sentryIssueId}`,
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  if (!user) {
    return null;
  }

  if (isLoading) {
    return <Loading sx={{ height: '100dvh' }} />;
  }

  if (showInformationPage) {
    return <SecurityRequirementPage onContinue={() => setShowInformationPage(false)} />;
  }

  const code = watch('verificationCode');

  return (
    <SetupPageWithoutProvider
      title="Set Up Two-Factor Authentication"
      subtitle="Enhance your account security by setting up two-factor authentication using an authenticator app"
      form={form}
      onSubmit={onSubmit}
    >
      <Fade in>
        <Stack spacing={4} width="100%">
          <Stepper activeStep={activeStep} sx={{ maxWidth: '400px', alignSelf: 'center' }}>
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <ActiveStep activeStep={activeStep} secret={secret} qrCode={qrCode} onSubmit={form.handleSubmit(onSubmit)} />

          <AuthenticationWrapper.Actions style={{ flexDirection: 'row', gap: theme.spacing(2) }}>
            <ActiveStepActions
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              user={user}
              isEnrolling={isSubmitting}
              handleNext={handleNext}
              code={code}
            />
          </AuthenticationWrapper.Actions>
        </Stack>
      </Fade>
    </SetupPageWithoutProvider>
  );
};

export const TOTPSetupPage = () => (
  <MFASetupPageContextProvider method="totp">
    <TOTPSetupPageContent />
  </MFASetupPageContextProvider>
);
