import { z } from 'zod';
import {
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDoc, collection } from 'firebase/firestore';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Stack, TextField } from '@mui/material';

import { db } from '../../../firebase-config';
import { MFA_ERROR_CODES } from '../constants';
import { MFAVerificationDialogue } from './MFAVerificationDialogue';
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator';

const updatePasswordSchema = z
  .object({
    currentPassword: z.string().min(1, 'Password is required'),
    newPassword: z.string().min(8, { message: 'Password must be at least 8 characters long.' }),
  })
  .refine((data) => data.newPassword !== data.currentPassword, {
    path: ['newPassword'],
    message: 'New password cannot be the same as the current password.',
  });

/**
 * @typedef {z.infer<typeof updatePasswordSchema>} UpdatePasswordSchema
 */

const FormSection = ({ setFormView, existingEmail, snackbar }) => {
  const [mfaResolver, setMfaResolver] = useState(
    /** @type {import('@firebase/auth').MultiFactorResolver | null} */ (null),
  );

  const form = useForm({
    resolver: zodResolver(updatePasswordSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  });

  const {
    setError,
    formState: { isSubmitting },
  } = form;

  const cancelPasswordUpdate = () => {
    setFormView(false);
    form.reset();
  };

  /**
   * @param {{data: UpdatePasswordSchema, isPostMFA?: boolean}} params
   */
  const handleUpdatePassword = async ({ data, isPostMFA = false }) => {
    const auth = getAuth();

    const { currentPassword, newPassword } = data;

    try {
      const { currentUser } = auth;

      if (!currentUser) {
        snackbar?.('User not found');

        return;
      }

      const date = Date.now();
      const credential = EmailAuthProvider.credential(existingEmail, currentPassword);

      snackbar?.('Updating...');

      if (!isPostMFA) {
        await reauthenticateWithCredential(currentUser, credential);
      }

      await updatePassword(currentUser, newPassword)
        .then(async () => {
          snackbar?.('Your password has been updated');

          await addDoc(collection(db, 'patients', currentUser?.uid, 'activity'), {
            author: 'System',
            createdAt: date,
            generalData: true,
            text: 'User updated their password.',
          });

          setFormView(false);
        })
        .catch((error) => {
          snackbar?.(`${error.code}: ${error.message}`);
        });
    } catch (err) {
      switch (err.code) {
        case MFA_ERROR_CODES.ARGUMENT_ERROR:
        case MFA_ERROR_CODES.INVALID_CREDENTIAL:
        case MFA_ERROR_CODES.WRONG_PASSWORD:
          setError('currentPassword', { message: 'Please check your password and try again.' });
          break;
        case MFA_ERROR_CODES.TOO_MANY_REQUESTS:
          setError('currentPassword', { message: 'Too many attempts, please try again later.' });
          break;
        case MFA_ERROR_CODES.WEAK_PASSWORD:
          setError('newPassword', { message: 'Your password is too weak. Please use a stronger password.' });
          break;
        case MFA_ERROR_CODES.MULTI_FACTOR_AUTH_REQUIRED:
          setMfaResolver(getMultiFactorResolver(auth, /** @type {import('@firebase/auth').MultiFactorError} */ (err)));
          break;
        default:
          snackbar?.('Unknown error: Please contact support');
          break;
      }
    }
  };

  /**
   * @param {UpdatePasswordSchema} data
   */
  const onSubmit = async (data) => {
    await handleUpdatePassword({ data });
  };

  const onSuccess = async () => {
    setMfaResolver(null);
    await form.handleSubmit(async (data) => {
      await handleUpdatePassword({ data, isPostMFA: true });
    })();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <TextField
          label="Current Password"
          type="Password"
          autoComplete="current-password"
          {...form.register('currentPassword')}
          variant="outlined"
          fullWidth
          error={!!form.formState.errors.currentPassword}
          helperText={form.formState.errors.currentPassword?.message}
        />

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '0.5rem' }}>
          <TextField
            label="New Password"
            type="password"
            autoComplete="new-password"
            {...form.register('newPassword')}
            variant="outlined"
            fullWidth
            error={!!form.formState.errors.newPassword}
            helperText={form.formState.errors.newPassword?.message}
          />
          <PasswordStrengthIndicator formId="newPassword" />
        </div>

        <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
          <Button type="submit" variant="contained" color="primary" loading={isSubmitting}>
            Update
          </Button>
          <Button color="primary" onClick={cancelPasswordUpdate} disabled={isSubmitting}>
            Cancel
          </Button>
        </Stack>

        <MFAVerificationDialogue mfaResolver={mfaResolver} setMfaResolver={setMfaResolver} onSuccess={onSuccess} />
      </form>
    </FormProvider>
  );
};

/**
 * @param {Object} props
 * @param {string} props.existingEmail
 * @param {((message: string) => void) | undefined} props.snackbar
 * @returns
 */
export const UpdatePassword = ({ existingEmail, snackbar }) => {
  const [formView, setFormView] = useState(false);

  return (
    <Stack direction="column" gap={2} width="100%">
      <TextField label="Password" type="Password" value="*********" variant="outlined" disabled fullWidth />

      {!formView ? (
        <Button color="primary" onClick={() => setFormView(true)}>
          Change Password
        </Button>
      ) : (
        <FormSection setFormView={setFormView} existingEmail={existingEmail} snackbar={snackbar} />
      )}
    </Stack>
  );
};
