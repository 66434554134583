/**
 * @param {Object} params
 * @param {FactorId} params.factorId
 * @param {import('firebase/auth').MultiFactorUser} params.multiFactorUser
 * @returns {boolean}
 */
export const isUserEnrolledInMFAMethod = ({ factorId, multiFactorUser }) => {
  const { enrolledFactors } = multiFactorUser;

  return enrolledFactors.some((factor) => factor.factorId === factorId);
};
