import { z } from 'zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { FirebaseError } from 'firebase/app';
import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { captureException as sentryCaptureException } from '@sentry/react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { Typography, Link, Button, TextField, InputAdornment, IconButton } from '@mui/material';

import { auth } from '../../../firebase-config';
import { logSignUpEvent } from '../../../analytics/logAnalyticsEvent';
import createPatientData from '../../../functions/create-patient-data';
import { PasswordStrengthIndicator } from '../components';
import { AuthenticationWrapper, classes } from '../AuthenticationWrapper';
import { useAuthenticationPageContext } from '../useAuthenticationPageContext';

const MIN_PASSWORD_LENGTH = 8;

const registerSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  password: z.string().min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`),
});

/**
 * @typedef {z.infer<typeof registerSchema>} FormType
 */

/**
 * Handles the error logic for the submission of the register form
 * @param {Object} params
 * @param {Error} params.error
 * @param {import('react-hook-form').UseFormSetError<FormType>} params.setError
 */
const handleOnSubmitErrors = ({ error, setError }) => {
  if (!(error instanceof FirebaseError)) {
    const sentryIssueId = sentryCaptureException(error, {
      level: 'error',
      extra: { issueIn: 'RegisterPage:handleOnSubmitError' },
    });

    setError('root', {
      type: 'sentry',
      message: `An unexpected error occurred. Try again later. Id: ${sentryIssueId}`,
    });

    return;
  }

  switch (error.code) {
    case 'auth/invalid-email':
      setError('email', { message: 'This email is not valid' });
      break;
    case 'auth/email-already-in-use':
      setError('email', { message: 'This email is already in use' });
      break;
    case 'auth/weak-password':
      setError('password', { message: 'Please enter a stronger password' });
      break;
    case 'auth/operation-not-allowed':
      setError('root', {
        message: 'This account has been disabled. Please contact support@candor.org.',
      });
      break;
    default: {
      const sentryIssueId = sentryCaptureException(error, {
        level: 'error',
        extra: { issueIn: 'RegisterPage:handleOnSubmitError', code: error.code },
      });

      setError('root', {
        type: 'sentry',
        message: `An unexpected error occurred. Please contact support@candor.org with ${error.code}. Id: ${sentryIssueId}`,
      });
    }
  }
};

/**
 * @typedef {Object} RegisterPageProps
 * @property {string} [formName=''] - Name of the form if registration is in form context
 * @property {number} [index=0] - Index for form context
 * @property {boolean} [formContext=false] - Whether registration is in form context
 * @property {Object} [formInputs] - Form inputs for context-based registration
 */

/**
 * RegisterPage Component - Handles new user registration
 * @param {RegisterPageProps} props - Component props
 */
export const RegisterPage = ({ formName = '', index = 0, formContext = false, formInputs = undefined }) => {
  const navigate = useNavigate();
  const { snackbar } = useAuthenticationPageContext();
  const [showPassword, setShowPassword] = React.useState(false);

  const form = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    register,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = form;

  /**
   * Handles the submission of the registration form
   * @param {FormType} data - The data from the registration form
   */
  const onSubmit = async (data) => {
    const cleanseEmail = data.email.trim().toLowerCase();

    try {
      const cred = await createUserWithEmailAndPassword(auth, cleanseEmail, data.password);
      logSignUpEvent();

      await createPatientData({
        userId: cred.user.uid,
        userEmail: cleanseEmail,
        ...(formContext && { formName, formInputs, index }),
      });

      await sendEmailVerification(cred.user);

      snackbar('Account created successfully! Please check your email to verify your account.');
    } catch (error) {
      handleOnSubmitErrors({ error, setError });
    }
  };

  return (
    <AuthenticationWrapper>
      <AuthenticationWrapper.Container id="register-page">
        <AuthenticationWrapper.Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <AuthenticationWrapper.Header>
            <AuthenticationWrapper.Title>Create Account</AuthenticationWrapper.Title>
          </AuthenticationWrapper.Header>
          <TextField
            size="small"
            label="Email"
            variant="outlined"
            autoComplete="username"
            className={classes.input}
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '0.5rem' }}>
            <TextField
              size="small"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              autoComplete="new-password"
              variant="outlined"
              className={classes.input}
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <PasswordStrengthIndicator />
          </div>

          {errors.root?.message && (
            <Typography variant="body2" color="error" align="center">
              {errors.root?.message}
            </Typography>
          )}

          <AuthenticationWrapper.Actions>
            <Button
              type="submit"
              className={classes.button}
              loading={isSubmitting}
              disabled={isSubmitSuccessful}
              loadingPosition="end"
              variant="contained"
              color="primary"
            >
              {isSubmitting ? 'Signing up' : 'Sign up'}
            </Button>

            <Typography variant="caption" align="center">
              By signing up you agree to the Candor Medical <br />
              <Link underline="none" target="_blank" href="https://www.candor.org/terms-and-conditions">
                Terms & conditions
              </Link>{' '}
              and{' '}
              <Link underline="none" target="_blank" href="https://www.candor.org/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>
          </AuthenticationWrapper.Actions>
        </AuthenticationWrapper.Form>
        {!formContext && (
          <Button variant="text" onClick={() => navigate('/login')} className={classes.link}>
            I already have an account
          </Button>
        )}
      </AuthenticationWrapper.Container>
    </AuthenticationWrapper>
  );
};
