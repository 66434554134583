import React from 'react';
import { Navigate } from 'react-router';

import Dashboard from '../../components/dashboard/dashboard';
import {
  tgaRoutes,
  adminRoutes,
  ordersRoutes,
  accountRoutes,
  consultRoutes,
  messagesRoutes,
  patientsRoutes,
  purchaseRoutes,
  catalogueRoutes,
  medicalInfoRoutes,
  appointmentsRoutes,
  pharmacyStockRoutes,
  assignedPatientsRoutes,
  authenticatedPaymentsRoutes,
  uploadSupportingDocumentsRoutes,
  purchaseHistoryRoutes,
  pharmaciesRoutesWithLegacyRoute,
} from './base-routes';
import { BaseNavigateWithRedirect } from './BaseNavigateWithRedirect';

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const verifiedUserRoutes = [
  {
    name: 'Dashboard',
    index: true,
    path: '',
    element: <Navigate replace to="/dashboard" />,
    type: 'item',
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    element: <Dashboard />,
    type: 'item',
  },
  ...accountRoutes,
  adminRoutes,
  assignedPatientsRoutes,
  ...authenticatedPaymentsRoutes,
  appointmentsRoutes,
  catalogueRoutes,
  consultRoutes,
  medicalInfoRoutes,
  messagesRoutes,
  ordersRoutes,
  patientsRoutes,
  ...pharmaciesRoutesWithLegacyRoute,
  tgaRoutes,
  uploadSupportingDocumentsRoutes,
  pharmacyStockRoutes,
  purchaseHistoryRoutes,
  purchaseRoutes,
  {
    name: 'Base Navigate With Redirect',
    path: '*',
    type: 'item',
    element: <BaseNavigateWithRedirect />,
  },
];
