import {
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getAuth,
} from 'firebase/auth';
import { z } from 'zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { Navigate, useNavigate } from 'react-router';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography, Button, TextField, Stack } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { isInIFrame } from '../../../utils';
import { MFAVerification } from '../components';
import { MFA_ERROR_CODES } from '../constants';
import { AuthenticationWrapper, classes } from '../AuthenticationWrapper';

/**
 * @typedef {Object} LoginPageProps
 * @property {boolean} [noPaper=false] - Flag to indicate if the form should not have a paper component
 * @property {boolean} [reauthenticate=false] - Flag to indicate if the form should be used for reauthentication
 */

const loginSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  password: z.string().min(1, 'Password is required'),
});

/**
 * @typedef {z.infer<typeof loginSchema>} FormType
 */

/**
 * Handles the error logic for the submission of the login form
 * @param {Object} params
 * @param {Error} params.error
 * @param {import('react-hook-form').UseFormSetError<FormType>} params.setError
 * @param {React.Dispatch<React.SetStateAction<import('@firebase/auth').MultiFactorResolver | null>>} params.setMfaResolver
 */
const handleOnSubmitErrors = ({ error, setError, setMfaResolver }) => {
  if (!(error instanceof FirebaseError)) {
    const sentryIssueId = sentryCaptureException(error, {
      level: 'error',
      extra: { issueIn: 'LoginPage:handleOnSubmitError' },
    });

    setError('root', {
      type: 'sentry',
      message: `An unexpected error occurred. Try again later. Id: ${sentryIssueId}`,
    });

    return;
  }

  switch (error.code) {
    case MFA_ERROR_CODES.USER_DISABLED:
      setError('email', { message: 'This account has been disabled. Please contact support.' });
      break;
    case MFA_ERROR_CODES.MULTI_FACTOR_AUTH_REQUIRED:
      setMfaResolver(
        getMultiFactorResolver(getAuth(), /** @type {import('@firebase/auth').MultiFactorError} */ (error)),
      );
      break;
    case MFA_ERROR_CODES.INVALID_EMAIL:
    case MFA_ERROR_CODES.USER_NOT_FOUND:
    case MFA_ERROR_CODES.WRONG_PASSWORD:
    case MFA_ERROR_CODES.INVALID_CREDENTIAL:
      setError('email', { message: 'Sorry, your login details are incorrect. Please try again.' });
      break;
    default: {
      const sentryIssueId = sentryCaptureException(error, {
        level: 'error',
        extra: { issueIn: 'LoginPage:handleOnSubmitError', code: error.code },
      });

      setError('root', {
        message: `An unexpected error occurred. Please contact support@candor.org with ${error.code}. Id: ${sentryIssueId}`,
      });
    }
  }
};

const noPaperStyle = {
  padding: 0,
};

/**
 * LoginPage Component - Handles user authentication through email/password
 * @param {LoginPageProps} props
 */
export const LoginPage = ({ noPaper = false, reauthenticate = false }) => {
  const navigate = useNavigate();
  const [mfaResolver, setMfaResolver] = useState(
    /** @type {import('@firebase/auth').MultiFactorResolver | null} */ (null),
  );

  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
  } = form;

  const onSuccess = () => {
    // Notify parent window of successful sign in
    if (isInIFrame()) {
      window.parent.postMessage({ type: 'SIGN_IN_SUCCESS' }, window.location.origin);

      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get('redirect');

    if (!redirect) {
      navigate('/dashboard');

      return;
    }

    searchParams.delete('redirect');
    navigate(`${redirect}?${searchParams.toString()}`);
  };

  /**
   * Handles the submission of the login form
   * @param {FormType} data - The data from the login form
   */
  const onSubmit = async (data) => {
    try {
      const auth = getAuth();
      const { currentUser } = auth;

      if (currentUser && reauthenticate) {
        const reauthUserCredential = await reauthenticateWithCredential(
          currentUser,
          EmailAuthProvider.credential(data.email.trim(), data.password),
        );
        await reauthUserCredential.user.getIdToken(true);
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, data.email.trim(), data.password);
        await userCredential.user.getIdToken(true);
      }

      onSuccess();
    } catch (error) {
      handleOnSubmitErrors({ error, setError, setMfaResolver });
    }
  };

  const handleRegisterOnClick = () => {
    navigate('/register');
  };

  const handlePasswordResetOnClick = () => {
    navigate('/reset-password', { state: { email: form.getValues('email') } });
  };

  // You can't be on this page if it is 'reauthenticate' mode and not in an iframe
  if (reauthenticate && window === window.top) {
    return <Navigate replace to="/dashboard" />;
  }

  // If MFA is required, show the MFA verification page
  if (mfaResolver) {
    return <MFAVerification resolver={mfaResolver} asChild={noPaper} onSuccess={onSuccess} />;
  }

  return (
    <AuthenticationWrapper>
      <AuthenticationWrapper.Container id="login-page" noPaper={noPaper} style={noPaper ? noPaperStyle : {}}>
        <AuthenticationWrapper.Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <AuthenticationWrapper.Header>
            <AuthenticationWrapper.Title>
              {reauthenticate ? 'Please sign in again' : 'Sign In'}
            </AuthenticationWrapper.Title>
          </AuthenticationWrapper.Header>
          <TextField
            size="small"
            label="Email"
            autoComplete="username"
            variant="outlined"
            className={classes.input}
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Stack direction="column" alignItems="center" justifyContent="center" width="100%">
            {!reauthenticate && (
              <Button
                disableRipple
                variant="text"
                disabled={isSubmitting}
                onClick={handlePasswordResetOnClick}
                className={classes.link}
                tabIndex={-1}
                sx={{
                  textTransform: 'none',
                  padding: 0,
                  alignSelf: 'flex-end',
                  fontSize: '0.75rem',
                }}
              >
                Forgot your password?
              </Button>
            )}
            <TextField
              size="small"
              type="password"
              label="Password"
              autoComplete="current-password"
              variant="outlined"
              className={classes.input}
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
            />

            {errors.root?.message && (
              <Typography variant="body2" color="error" align="center">
                {errors.root?.message}
              </Typography>
            )}
          </Stack>
          <AuthenticationWrapper.Actions>
            <Button
              type="submit"
              className={classes.button}
              variant="contained"
              color="primary"
              loading={isSubmitting}
              loadingPosition="end"
              disabled={isSubmitSuccessful}
            >
              {isSubmitting ? 'Signing in' : 'Sign in'}
            </Button>
          </AuthenticationWrapper.Actions>
        </AuthenticationWrapper.Form>
        {!reauthenticate && (
          <Button disabled={isSubmitting} variant="text" onClick={handleRegisterOnClick} className={classes.link}>
            I don&apos;t have an account
          </Button>
        )}
      </AuthenticationWrapper.Container>
    </AuthenticationWrapper>
  );
};
