import React from 'react';
import { Accordion as MuiAccordion } from '@mui/material';

import { Trigger } from './Trigger';
import { useAccordion } from './useAccordion';
import { Details } from './Details';

const HEADER_HEIGHT = '54px !important';

/**
 * @param {Omit<import('@mui/material').AccordionProps, 'expanded' | 'onChange' | 'ref'>} props
 */
export const Accordion = ({ children, sx, ...props }) => {
  const { expanded, accordionRef, handleOnChange } = useAccordion();

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleOnChange}
      elevation={0}
      sx={{
        margin: '0 !important',

        '&:before': { display: 'none' },

        '& .MuiAccordionSummary-root': {
          minHeight: HEADER_HEIGHT,
          maxHeight: HEADER_HEIGHT,
          height: HEADER_HEIGHT,
        },
        '& .MuiAccordionSummary-content': {
          margin: '0',
        },
        ...sx,
      }}
      ref={accordionRef}
      {...props}
    >
      {children}
    </MuiAccordion>
  );
};

Accordion.Trigger = Trigger;
Accordion.Details = Details;
