import { captureException } from '@sentry/react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { db } from '../../../firebase-config';

/**
 * @param {Object} params
 * @param {string | undefined} params.userId
 * @param {FactorId} params.factorId
 */
export const addSuccessful2FAToActivityLog = async ({ userId, factorId }) => {
  if (!userId) {
    return;
  }

  try {
    await addDoc(collection(db, 'patients', userId, 'activity'), {
      author: 'System',
      createdAt: serverTimestamp(),
      generalData: true,
      text: `User successfully added two-factor authentication to their account (${factorId})`,
      metadata: {
        action: 'ADDED_2FA',
        actionByUid: userId,
        data: {
          factorId,
        },
      },
    });
  } catch (error) {
    captureException(error, {
      level: 'error',
      extra: {
        userId,
        factorId,
      },
    });
  }
};
