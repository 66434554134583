import React from 'react';

const PaymentContext = React.createContext({
  treatmentsToRead: [],
  selectedTreatments: [],
  handlePaymentButton: () => {},
  isCheckoutLoading: false,
  termsRead: false,
  description: '',
  checkoutData: {},
  setCheckoutData: () => {},
  formName: '',
  scriptMode: false,
  handleTermsRead: () => {},
  shippingAlert: '',
  promoCode: '',
  setPromoCode: () => {},
  pharmacyConsentRead: false,
  isPickup: false,
  isPharmacyPending: false,
  showPaymentMethods: false,
  setShowPaymentMethods: () => {},
  paymentProcessor: '',
  treatmentTranslator: {},
  reference: '',
  isPromoLoading: false,
  setIsPromoLoading: () => {},
  selectedPharmacy: '',
});

export default PaymentContext;
