import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { withPrivateRoute } from '../withPrivateRoute';

const { SUPPORT, NURSE, DOCTOR, DIRECTOR } = USER_TYPES;

const PatientsPageLayout = React.lazy(() =>
  import('../../../features/patients/pages').then((module) => ({
    default: module.PatientsPageLayout,
  })),
);

const PatientsPage = React.lazy(() =>
  import('../../../features/patients/pages').then((module) => ({
    default: module.PatientsPageContainer,
  })),
);

const PatientPage = React.lazy(() =>
  import('../../../features/patients/pages').then((module) => ({
    default: module.PatientPageContainer,
  })),
);

/**
 * @satisfies {ExtendedRouteObject}
 */
export const patientsRoutes = {
  name: 'Patients',
  path: 'patients',
  type: 'layout',
  ...withPrivateRoute(Loadable(PatientsPageLayout), {
    userCanAccess: ({ userType }) => allowedUserType([SUPPORT, NURSE, DOCTOR, DIRECTOR], userType),
  }),
  children: [
    {
      name: 'Patients',
      index: true,
      path: '',
      type: 'item',
      element: Loadable(PatientsPage),
    },
    {
      name: 'Patient',
      path: ':id',
      type: 'item',
      element: Loadable(PatientPage),
    },
  ],
};
